import { Routes, Route } from 'react-router-dom'
import { WebText, TableReservationMenu, PageNotFound, Settings, RestauranteAndBares, Wellness, Restaurant, Bar, WellnessDetail, Theme, OnlineCheckIn, TableReservation, Checkout, MaintenanceRequest, EMenu, Home, HotelHome, HotelReservationDetails, QrCode} from '../pages'
import { ProtectedRoute } from './ProtectedRoute'
import { useEffect, useState, useContext } from "react";
import { AppContext } from '../context/AppContext';
import { getHotelById } from '../services';
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { RootLayout } from '../pages/RootLayout';
import dayjs from 'dayjs';
import { t } from 'i18next';
import 'dayjs/locale/pt';
import 'dayjs/locale/en';

/**
 * Component that sets up routes for navigation
 * @returns Routing for navigation
 */
export const AllRoutes = () => {
  const [darkMode]=useState(JSON.parse(localStorage.getItem(`darkMode`)) || false)
  const [state, dispatch] = useContext(AppContext);
  const [cookie, setCookie] = useCookies(['platformUid', 'currentHotel','detailId','sUid']); // eslint-disable-line
  const pathname = window.location.pathname;   // Obter o pathname completo
  const parts = pathname.split('/'); // Dividir o pathname por '/' para obter as partes

  dayjs.locale(cookie.currentHotel?.defaultCultureCode? cookie.currentHotel?.defaultCultureCode.substring(0,2) : "pt");

  // Inicializa platformUid, detailId e sUid com valores padrão
  let platformUid, detailId, sUid;

    // Verifica se o pathname começa com '/overview'
    if (pathname.includes("/home")) {
      platformUid = parts[2]; // Obtém platformUid
      sUid = parts[3]; // Obtém sUid
    }
    // Verifica se o pathname começa com '/hotel'
    else if (pathname.includes("/hotel")) {
      platformUid = parts[2]; // Obtém platformUid
      detailId = parts[3]; // Obtém detailId
    }

  /**
   * Function that set dinamically the theme each time we enter a hotel enviroment. If no hotel is selected, it sets the "Host theme".
   */
    function atualizarTema() {
      var root = document.querySelector(':root');

      if (state.selectedHotel && state.selectedHotel.id) {
        root.style.setProperty('--border1', state.selectedHotel.theme.border1);
        root.style.setProperty('--bg1', state.selectedHotel.theme.bg1);
        root.style.setProperty('--bg2', state.selectedHotel.theme.bg2);
        root.style.setProperty('--txt1', state.selectedHotel.theme.txt1);
        root.style.setProperty('--txt2', state.selectedHotel.theme.txt2);
        root.style.setProperty('--navbar_bg', state.selectedHotel.theme.navbar_bg);
        root.style.setProperty('--sidebar_bg', state.selectedHotel.theme.sidebar_bg);
        root.style.setProperty('--sidebar_txt1', state.selectedHotel.theme.sidebar_txt1);
        root.style.setProperty('--sidebar_txt2', state.selectedHotel.theme.sidebar_txt2);

        root.style.setProperty('--sidebar_title_color', state.selectedHotel.theme.sidebar_title_color);
        root.style.setProperty('--sidebar_text_color', state.selectedHotel.theme.sidebar_text_color);
        root.style.setProperty('--sidebar_icons_color', state.selectedHotel.theme.sidebar_icons_color);
        root.style.setProperty('--sidebar_bg_color', state.selectedHotel.theme.sidebar_bg_color);
        root.style.setProperty('--sidebar_border_color', state.selectedHotel.theme.sidebar_border_color);
        root.style.setProperty('--sidebar_hover_bg_color', state.selectedHotel.theme.sidebar_hover_bg_color);
        root.style.setProperty('--sidebar_hover_text_color', state.selectedHotel.theme.sidebar_hover_text_color);

        root.style.setProperty('--navbar_icons_color', state.selectedHotel.theme.navbar_icons_color);
        root.style.setProperty('--navbar_bg_color', state.selectedHotel.theme.navbar_bg_color);
        root.style.setProperty('--navbar_border_color', state.selectedHotel.theme.navbar_border_color);
        root.style.setProperty('--navbar_hover_bg_color', state.selectedHotel.theme.navbar_hover_bg_color);
        root.style.setProperty('--navbar_hover_text_color', state.selectedHotel.theme.navbar_hover_text_color);

        root.style.setProperty('--page_bg_color', state.selectedHotel.theme.page_bg_color);

        root.style.setProperty('--sections_title_color', state.selectedHotel.theme.sections_title_color);
        root.style.setProperty('--sections_text_color', state.selectedHotel.theme.sections_text_color);
        root.style.setProperty('--sections_icons_color', state.selectedHotel.theme.sections_icons_color);
        root.style.setProperty('--sections_bg_color', state.selectedHotel.theme.sections_bg_color);
        root.style.setProperty('--sections_border_color', state.selectedHotel.theme.sections_border_color);
        root.style.setProperty('--sections_hover_bg_color', state.selectedHotel.theme.sections_hover_bg_color);
        root.style.setProperty('--sections_hover_text_color', state.selectedHotel.theme.sections_hover_text_color);

        root.style.setProperty('--card_title_color', state.selectedHotel.theme.card_title_color);
        root.style.setProperty('--card_text_color', state.selectedHotel.theme.card_text_color);
        root.style.setProperty('--card_icons_color', state.selectedHotel.theme.card_icons_color);
        root.style.setProperty('--card_bg_color', state.selectedHotel.theme.card_bg_color);
        root.style.setProperty('--card_border_color', state.selectedHotel.theme.card_border_color);
        root.style.setProperty('--card_hover_bg_color', state.selectedHotel.theme.card_hover_bg_color);
        root.style.setProperty('--card_hover_text_color', state.selectedHotel.theme.card_hover_text_color);

        root.style.setProperty('--footer_text_color', state.selectedHotel.theme.footer_text_color);

    } else {
        root.style.setProperty('--sidebar_title_color', state.defaultTheme.sidebar_title_color);
        root.style.setProperty('--sidebar_text_color', state.defaultTheme.sidebar_text_color);
        root.style.setProperty('--sidebar_icons_color', state.defaultTheme.sidebar_icons_color);
        root.style.setProperty('--sidebar_bg_color', state.defaultTheme.sidebar_bg_color);
        root.style.setProperty('--sidebar_border_color', state.defaultTheme.sidebar_border_color);
        root.style.setProperty('--sidebar_hover_bg_color', state.defaultTheme.sidebar_hover_bg_color);
        root.style.setProperty('--sidebar_hover_text_color', state.defaultTheme.sidebar_hover_text_color);

        root.style.setProperty('--navbar_icons_color', state.defaultTheme.navbar_icons_color);
        root.style.setProperty('--navbar_bg_color', state.defaultTheme.navbar_bg_color);
        root.style.setProperty('--navbar_border_color', state.defaultTheme.navbar_border_color);
        root.style.setProperty('--navbar_hover_bg_color', state.defaultTheme.navbar_hover_bg_color);
        root.style.setProperty('--navbar_hover_text_color', state.defaultTheme.navbar_hover_text_color);

        root.style.setProperty('--page_bg_color', state.defaultTheme.page_bg_color);

        root.style.setProperty('--sections_title_color', state.defaultTheme.sections_title_color);
        root.style.setProperty('--sections_text_color', state.defaultTheme.sections_text_color);
        root.style.setProperty('--sections_icons_color', state.defaultTheme.sections_icons_color);
        root.style.setProperty('--sections_bg_color', state.defaultTheme.sections_bg_color);
        root.style.setProperty('--sections_border_color', state.defaultTheme.sections_border_color);
        root.style.setProperty('--sections_hover_bg_color', state.defaultTheme.sections_hover_bg_color);
        root.style.setProperty('--sections_hover_text_color', state.defaultTheme.sections_hover_text_color);

        root.style.setProperty('--card_title_color', state.defaultTheme.card_title_color);
        root.style.setProperty('--card_text_color', state.defaultTheme.card_text_color);
        root.style.setProperty('--card_icons_color', state.defaultTheme.card_icons_color);
        root.style.setProperty('--card_bg_color', state.defaultTheme.card_bg_color);
        root.style.setProperty('--card_border_color', state.defaultTheme.card_border_color);
        root.style.setProperty('--card_hover_bg_color', state.defaultTheme.card_hover_bg_color);
        root.style.setProperty('--card_hover_text_color', state.defaultTheme.card_hover_text_color);

        root.style.setProperty('--footer_text_color', state.defaultTheme.footer_text_color);
    }
  }

  atualizarTema()

  useEffect(() => {
    const fetchHotelData = async () => {
      if (platformUid !== cookie.currentHotel?.hotelId?.substring(0, 8)) {
        try {
          dispatch({ type: "SET_LOADING", payload: true });
          const hotelData = await getHotelById(platformUid);
          dispatch({ type: "SET_CURRENT_HOTEL", payload: hotelData });
          const cookieOptions = { path: "/" };
          setCookie('currentHotel', JSON.stringify(hotelData), cookieOptions);
          setCookie('platformUid', platformUid, cookieOptions);
          if (detailId) {
            setCookie('detailId', detailId, cookieOptions);
          }
          if (sUid) {
            setCookie('sUid', sUid, cookieOptions);
          }
        } catch (error) {
          console.error("App: Error fetching hotel info:", error);
          toast.error("App: Unable to get hotel info!");
        } finally {
          dispatch({ type: "SET_LOADING", payload: false });
        }
      }
    };
    fetchHotelData();
  }, [platformUid, sUid, detailId, cookie.currentHotel?.hotelId]); // eslint-disable-line

  useEffect(() => {
    if(cookie.currentHotel){
      setCookie('cultureCode', cookie.currentHotel.defaultCultureCode, {path:"/"})}
  }, [platformUid, cookie.currentHotel?.defaultCultureCode]); // eslint-disable-line

  /**
   * UseEffect to update the darkMode theme state in the App.
   */
  useEffect(()=>{
    localStorage.setItem(`darkMode`, JSON.stringify(darkMode));
    if(darkMode){
      document.documentElement.classList.add(`dark`)
    } else {
      document.documentElement.classList.remove(`dark`)
    }
  },[]) // eslint-disable-line

  return (
    <div className="">
        <Routes className="">
            <Route element={<RootLayout />} >
              <Route path="/home/:platformUid/:sUid" element={<Home />} />

              <Route path="/hotel/:platformUid/:detailId" element={<HotelHome />} />
              <Route path="/hotel/:platformUid/:detailId/reservation" element={<HotelReservationDetails />} />
              <Route path="/hotel/:platformUid/:detailId/checkout" element={<Checkout />} />
              <Route path="/hotel/:platformUid/:detailId/qrcode" element={<QrCode />} />


              <Route path="/hotel/:platformUid/:detailId/maintenancerequest" element={<MaintenanceRequest />} />
              <Route path="/hotel/:platformUid/:detailId/webtext/:key" element={<WebText />} />

              <Route path="/hotel/:platformUid/:detailId/checkin/:sUid/:resNo/:cS" element={<OnlineCheckIn />} />

              <Route path="/hotel/:platformUid/:detailId/checkindirect/:sUid/:resNo/:cS" element={<OnlineCheckIn />} />


              <Route path="/hotel/:platformUid/:detailId/treservationmenu/:sUid/:keys" element={<TableReservationMenu />} />
              <Route path="/hotel/:platformUid/:detailId/treservation/:sUid/:key" element={<TableReservation />} />

              <Route path="/hotel/:platformUid/treservationmenudirect/:keys" element={<TableReservationMenu />} />
              <Route path="/hotel/:platformUid/treservationdirect/:key" element={<TableReservation />} />


              <Route path="/hotel/:platformUid/:detailId/emenu/:sUid/:keys" element={<EMenu />} />

              <Route path="/hotel/:hotelId/restaurantandbars" element={<RestauranteAndBares />} end />
              <Route path="/hotel/:hotelId/restaurant/:serviceId" element={<Restaurant />}/>
              <Route path="/hotel/:hotelId/bar/:serviceId" element={<Bar />} />

              <Route path="/hotel/:hotelId/wellness" element={<ProtectedRoute><Wellness /></ProtectedRoute>} end />
              <Route path="/hotel/:hotelId/wellness/:serviceId" element={<ProtectedRoute><WellnessDetail /></ProtectedRoute>} />

              <Route path="/settings" element={<Settings />} end />
              <Route path="/theme" element={<Theme />} end />

              <Route path="*" element={<PageNotFound />} />
            </Route>

        </Routes>
              {/* MobileFooter visível apenas em telas menores que 'md' */}
    </div>
  )
}
