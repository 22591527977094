import { Sun } from "lucide-react"
import { Link, useLocation } from "react-router-dom"
import Logo from '../../assets/logos/Host_GXP-Dashboard-Black.png'
import Logo_dark from '../../assets/logos/Host_GXP-Dashboard.png'
import { useEffect, useState, useContext } from "react"
import { useCookies } from "react-cookie"
import { getHostProfile } from "../../services"
import { toast } from "react-toastify"
import { AppContext } from "../../context/AppContext"

export const MobileHeader = () => {
    const [darkMode, setDarkMode]=useState(JSON.parse(localStorage.getItem(`darkMode`)) || false)
    const [cookies, setCookie] = useCookies(['sUid', 'profileKey', 'GUid', "guest", "initialLink"]);
    const [state, dispatch] = useContext(AppContext);
    const { pathname } = useLocation();
    const showMenus = !pathname.includes("/checkindirect");

    //     /**
    //  * UseEffect that search host info using shortuid and hotel subscriptionKey
    //  */
    //     useEffect(() => {
    //       if(state.currentHotel.subscriptionKey){
    //       getHostProfile(cookies.sUid,cookies.profileKey,state.currentHotel.subscriptionKey)
    //       .then((result) => {
    //         const data = result;
    //         // Define o user
    //         dispatch({ type: "SET_USER_DETAILS", payload: data });
    //         setCookie('profileKey', data.ProfileKey, {path:"/"})
    //         setCookie('GUid', data.Uid, {path:"/"})
    //         setCookie('guest', JSON.stringify(data), {path:"/"})
    //       })
    //       .catch((error) => {
    //         toast.error("Unable to get host!");
    //       })
    //     }
    //     },[cookies.sUid,cookies.profileKey,state.currentHotel.subscriptionKey]) // eslint-disable-line


  return (
    <header className={`bg-white p-4 flex justify-between items-center sticky top-0 z-10 shadow-md ${showMenus? "": ""}`}>
    <Link to={cookies.initialLink} className="flex items-center justify-between mr-4">
      <img src={darkMode ? Logo_dark : Logo } className="mr-3 h-8 " alt="HHS Logo"/>
    </Link>        <div className="flex items-center space-x-4">
    <span>16:47</span>
    <span>20°C</span>
    <Sun className="text-yellow-500" />
  </div>
</header>  )
}
