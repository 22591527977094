import { useParams, useLocation } from "react-router-dom";
import { Navbar, SidebarHotel, Footer } from "../../components";
import { AppContext } from "../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {ReservationComponent} from "./components/ReservationComponent";
import { getOutlet } from "../../services";
import { toast } from "react-toastify";

/**
 * @module TableReservation
 * @description Main component responsible for rendering the table reservation page.
 * It retrieves service information based on the route parameters and manages the
 * state for the selected service, days in the calendar, and main dial codes.
 * It also includes navigation and layout components for a complete user experience.
 *
 * @returns {JSX.Element} The table reservation component.
 *
 * @example
 * <TableReservation />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const TableReservation = () => {
  const { key, sUid } = useParams();
  const [state, dispatch] = useContext(AppContext);
  const [cookie, setCookie] = useCookies(["sUid","currentHotel"]); // eslint-disable-line
  const [service, setService] = useState([])
  const [daysInCalendar, setDaysInCalendar] = useState()
  const [mainDialCodes, setMainDialCodes] = useState([])
  const [withoutReservation, setWithoutReservation] = useState(false)
  const location = useLocation();

/**
 * @function useEffectSetSUid
 * @description A React effect hook that runs when the component mounts or when the `sUid` value changes.
 * This hook checks if the `sUid` parameter is available and sets it as a cookie named "sUid".
 * The cookie is accessible throughout the entire site (indicated by `path: "/"`).
 *
 * @param {string} sUid - The unique identifier for the user session or specific user.
 *
 * @effect This effect will update the cookie whenever the `sUid` changes.
 * It ensures that the current session identifier is stored for reference in future requests.
 *
 * @dependencies [sUid] - The effect depends on `sUid`, meaning it will run whenever `sUid` changes.
 *
 * @example
 * // Example usage of sUid in useEffect
 * useEffect(() => {
 *   if (sUid) {
 *     setCookie("sUid", sUid, { path: "/" });
 *   }
 * }, [sUid]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  useEffect(() => {
    if (sUid) {
      setCookie("sUid", sUid, { path: "/" });}
  }, [sUid]);

  /**
 * @function useEffectSetService
 * @description This effect fetches outlet information when the `key` changes.
 * It uses the `getOutlet` function to retrieve details based on the current hotel stored in cookies.
 * Upon a successful fetch, it updates the state for `service`, `daysInCalendar`, and `mainDialCodes`.
 * If an error occurs during the fetch, it logs the error and displays a toast notification to the user.
 *
 * @dependencies [key] - The effect will run whenever the `key` changes. It ensures the latest outlet information is fetched based on the provided `key`.
 *
 * @example
 * useEffect(() => {
 *   if (key) {
 *     getOutlet([key], cookie.currentHotel)
 *       .then((data) => {
 *         console.log(data);
 *         setService(data.outletInfo[0]);
 *         setDaysInCalendar(data.daysInCalendar);
 *         setMainDialCodes(data.mainDialCodes);
 *       })
 *       .catch(error => {
 *         console.error("TableReservation: Error fetching service info:", error);
 *         toast.error("TableReservation: Unable to get service info!");
 *       });
 *   }
 * }, [key]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  useEffect(()=>{
    if(key&&cookie.currentHotel){
      getOutlet([key], cookie.currentHotel )
      .then((data) => {
        console.log(data)
          setService(data.outletInfo[0])
          setDaysInCalendar(data.daysInCalendar)
          setMainDialCodes(data.mainDialCodes)})
      .catch(error => {
        console.error("TableReservation: Error fetching service info:", error);
        toast.error("TableReservation: Unable to get service info!");})}}
    ,[key,cookie.currentHotel])

    useEffect(()=>{
       // Verifica se o pathname começa com '/overview'
  if (location.pathname.includes("/treservationdirect")) {
    setWithoutReservation(true)
  }
    },[])

    if(!withoutReservation){
  return (
    <>{ service && key && daysInCalendar && cookie.currentHotel &&
      <div className={`${state.darkMode ? "" : ""} antialiased dark:bg-gray-900 `} >
          <section className="flex-grow p-1 ">
            <ReservationComponent withoutReservation={withoutReservation} service={service} daysInCalendar={daysInCalendar} mainDialCodes={mainDialCodes} />
          </section>
      </div>
}
    </>
  );}

  if(withoutReservation){
    return (
      <>{ service && key && daysInCalendar && cookie.currentHotel &&
        <div className={`${state.darkMode ? "" : ""} antialiased dark:bg-gray-900`} >
            <section className="flex-grow p-1">
              <ReservationComponent withoutReservation={withoutReservation} service={service} daysInCalendar={daysInCalendar} mainDialCodes={mainDialCodes} />
            </section>
          </div>}
      </>
    );}


};
