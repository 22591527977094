import Api from "./urlBuilder"

export async function getOutlet(outletCode, hotel){
  const requestOptions = {
      method:"GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers:{"Content-Type":"application/json",
               "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,}
  }

  const param ={
    outletCodes: outletCode
  }

  const response = await fetch(Api.getUrl("/api/gxplocal/bridge/Gxp/TableReservation/Outlet",param), requestOptions)

  if(!response.ok){
      throw {message: response.statusText, status: response.status}//eslint-disable-line
  }
  const data = await response.json()
  return data
}

export async function getOutletSchedules(outletCode, hotel, fromDate, toDate, pax){
  const requestOptions = {
      method:"GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers:{"Content-Type":"application/json",
               "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,}
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/TableReservation/Outlet/Schedules?outletCode=${outletCode}&fromDate=${fromDate}&toDate=${toDate}&pax=${pax}`, requestOptions)

  if(!response.ok){
      throw {message: response.statusText, status: response.status}//eslint-disable-line
  }
  const data = await response.json()
  return data
}

  //https://gxp-api-caewbzg7h4b4dpet.westeurope-01.azurewebsites.net/api/gxplocal/bridge/Gxp/TableReservation/Outlet/Schedules?outletCode=2&fromDate=2024-10-10&toDate=2024-10-20&pax=2

  export async function saveTableReservation( hotel, dataForm){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,},
        body: JSON.stringify(dataForm)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/TableReservation/Save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}