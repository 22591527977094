import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

/**
 * @module TimeStep
 * @description Renders a selection step for available reservation times.
 * It allows users to choose a time slot from the provided list of available times.
 * The selected time is highlighted, and clicking a time updates the state and advances
 * to the next step in the reservation process.
 *
 * @param {Object} props - The component properties.
 * @param {function} props.handleTimeChange - Function to handle the change of the selected time.
 * @param {Object} props.fadeVariants - Variants for Framer Motion animations.
 * @param {function} props.setStep - Function to update the current step in the reservation process.
 * @param {string} props.time - Currently selected time for the reservation.
 * @param {Array<string>} props.availableTimes - Array of strings representing available time slots.
 *
 * @returns {JSX.Element} The TimeStep component for selecting reservation times.
 *
 * @example
 * <TimeStep
 *   handleTimeChange={changeTimeHandler}
 *   fadeVariants={fadeAnimationVariants}
 *   setStep={updateStep}
 *   time={selectedTime}
 *   availableTimes={['12:00', '13:00', '14:00']}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const TimeStep = ({ handleTimeChange, fadeVariants, setStep, time, availableTimes }) => {
  const [t] = useTranslation("global");

  return (
    <motion.div variants={fadeVariants} initial="hidden" animate="visible" exit="exit">
      <h2 className="text-xl mb-4">{`${t(`TimeStep.whattime`)}`}</h2>
      <div className="grid grid-cols-3 gap-4">
        {availableTimes.map((t) => (
          <button type='button' key={t} onClick={() => { handleTimeChange(t); setStep(4); }} className={`p-2 border rounded transition-transform duration-300 ease-in-out transform hover:scale-105 hover:bg-teal-100 hover:border-teal-600 ${ time === t ? 'bg-teal-100 border-teal-600' : 'border-gray-300' }`} >
            {t}
          </button>
        ))}
      </div>
    </motion.div>
  );
};