import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";


/**
 * @module DocumentExpireDate
 * @description Component for managing the document expiration date of a guest.
 * This component includes an input field for selecting the expiration date,
 * with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing document expiration date data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered document expiration date input field.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the DocumentExpireDate component
 * <DocumentExpireDate
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const DocumentExpireDate = ({handleGuestChange, guest, requiredFields, error, cS }) => {
  const [t] = useTranslation("global");

    // Determine if the document expiration date field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "DocVal" && field.IsMandatory);

    /**
     * @description Formats the date string into 'yyyy-MM-dd' format.
     *
     * @function formatDate
     * @param {string} dateString - The date string to format.
     * @returns {string} The formatted date string in 'yyyy-MM-dd' format or an empty string if invalid.
     *
     * @example
     * // Example usage of formatDate
     * const formattedDate = formatDate(guest.data.DocVal);
     */    const formatDate = (dateString) => {
      if (!dateString) return ""; // Return an empty string if the date is null or undefined
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // Get only the date part in 'yyyy-MM-dd' format
    };

  return (
    <div>
    <label htmlFor="dataValidade" required className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >{`${t(`DocumentExpireDate.expiredate`)}`}<span className="text-gray-400 ml-2">{isRequired?`( * )\u00A0`:``}</span></label>
    <input name="DocVal" required={isRequired}  onChange={(e)=>handleGuestChange(guest.id,e.target.name,e.target.value)} value={formatDate(guest.data.DocVal) || ""}  type="date" id="dataValidade" className={`w-full px-4 py-2 text-xl rounded-lg border ${error ? 'border-red-500' : 'border-gray-700'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`} />
    {error && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span> </div>}
    </div>  )
}
