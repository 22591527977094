import { motion } from "framer-motion";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

/**
 * @module DateStep
 * @description Component for selecting a date in a calendar interface.
 * It displays the current month and allows users to navigate through months,
 * selecting available dates for an event. The available dates are highlighted
 * and can be selected, triggering a change in the date state.
 *
 * @param {function} handleDateChange - Callback function invoked when a date is selected.
 *                                       Receives the selected date as a parameter.
 * @param {Object} fadeVariants - Animation variants for the component's entry and exit transitions.
 * @param {function} setStep - Function to update the current step in a multi-step process.
 * @param {string} date - The currently selected date in a string format (e.g., 'YYYY-MM-DD').
 * @param {Array<string>} availableDates - List of available dates for selection, formatted as 'YYYY-MM-DD'.
 * @param {number} daysInCalendar - Number of days to display in the calendar (e.g., number of days
 *                                   after the current date that can be selected).
 *
 * @returns {JSX.Element} The rendered calendar interface for date selection.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the DateStep component
 * <DateStep
 *   handleDateChange={handleDateChange}
 *   fadeVariants={fadeVariants}
 *   setStep={setStep}
 *   date={selectedDate}
 *   availableDates={['2024-10-15', '2024-10-16', '2024-10-20']}
 *   daysInCalendar={30}
 * />
 */
export const DateStep = ({ handleDateChange, fadeVariants, setStep, date, availableDates, daysInCalendar }) => {
  const today = dayjs();
  const [currentMonth, setCurrentMonth] = useState(today);
  const [t] = useTranslation("global");


/**
 * @const {Object} lastAvailableDate
 * @description Calculates the last available date based on the current date and the number of days
 * specified by `daysInCalendar`. This value is memoized to avoid unnecessary recalculations on re-renders,
 * only updating when `daysInCalendar` changes.
 *
 * @param {Object} today - The current date object, usually obtained from the dayjs library.
 * @param {number} daysInCalendar - The number of days to add to the current date to determine the last available date.
 *
 * @returns {Object} The last available date as a dayjs object.
 *
 * @example
 * const lastAvailableDate = useMemo(() => today.add(daysInCalendar, 'day'), [daysInCalendar]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
const lastAvailableDate = useMemo(() => today.add(daysInCalendar, 'day'), [daysInCalendar]);

/**
 * @function getMonthAndYear
 * @description Formats a given date object into a string representing the full month name
 * and year in the format "MMMM de YYYY" (e.g., "Outubro de 2024").
 *
 * @param {Object} date - A dayjs date object to be formatted.
 *
 * @returns {string} The formatted date string representing the month and year.
 *
 * @example
 * const monthYear = getMonthAndYear(dayjs('2024-10-01'));
 * // monthYear will be "Outubro de 2024"
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const getMonthAndYear = (date) => {
    return date.format('MMMM [de] YYYY');
  };

  /**
 * @function changeMonth
 * @description Updates the current month by adding or subtracting a specified number of months
 * based on the direction provided. A positive direction moves the month forward, while a
 * negative direction moves it backward.
 *
 * @param {number} direction - The number of months to change. Positive values move forward,
 *                             and negative values move backward in the calendar.
 *
 * @returns {void} This function does not return a value but updates the state of the current month.
 *
 * @example
 * // To change the month to the next month:
 * changeMonth(1);
 *
 * // To change the month to the previous month:
 * changeMonth(-1);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const changeMonth = (direction) => {
    setCurrentMonth(prev => prev.add(direction, 'month'));
  };

  /**
 * @const {number} daysInMonth
 * @description Calculates the number of days in the current month represented by `currentMonth`.
 * This value is derived from the dayjs library, providing the total count of days in the month.
 *
 * @returns {number} The number of days in the current month.
 *
 * @example
 * const totalDays = daysInMonth; // e.g., 31 for October 2024
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const daysInMonth = currentMonth.daysInMonth();

  /**
 * @const {number} firstDayOfMonth
 * @description Determines the day of the week for the first day of the current month.
 * This value is obtained using the dayjs library, where 0 represents Sunday, 1 represents Monday,
 * and so on up to 6 for Saturday.
 *
 * @returns {number} The day of the week for the first day of the current month,
 *                   with 0 representing Sunday.
 *
 * @example
 * const firstDay = firstDayOfMonth; // e.g., 2 for a Tuesday
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const firstDayOfMonth = currentMonth.startOf('month').day();

  /**
 * @const {number} adjustedStartingDay
 * @description Adjusts the starting day index for the calendar display.
 * If the first day of the month is Sunday (0), it is adjusted to 6 (Saturday) to align
 * with a typical week layout starting from Monday. Otherwise, it reduces the day index by 1.
 *
 * @returns {number} The adjusted starting day index for rendering the calendar.
 *
 * @example
 * const startingDay = adjustedStartingDay; // e.g., 0 for a Monday start
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const adjustedStartingDay = (firstDayOfMonth === 0) ? 6 : firstDayOfMonth - 1;

/**
 * @const {Array<Object|null>} calendarCells
 * @description Creates an array of date objects for the calendar display,
 * containing a total of 42 cells (6 weeks). Each cell corresponds to a day of the month.
 * The cells are adjusted based on the starting day of the month and the total number of days
 * in the month. If a cell does not correspond to a valid day in the current month, it is set to null.
 *
 * @returns {Array<Object|null>} An array of dayjs date objects for the current month,
 *                               with null values for cells that do not correspond to valid days.
 *
 * @example
 * const cells = calendarCells; // An array of 42 elements, where some are date objects
 *                               // and others are null depending on the month layout.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const calendarCells = Array.from({ length: 42 }, (_, i) => {
    const dayNumber = i - adjustedStartingDay + 1;
    if (dayNumber > 0 && dayNumber <= daysInMonth) {
      return dayjs(currentMonth).date(dayNumber);
    }
    return null;
  });

 /**
 * @function isDateAvailable
 * @description Checks if a given date is available for selection. A date is considered available
 * if it meets the following conditions:
 * 1. It is included in the list of available dates.
 * 2. It is after the current day.
 * 3. It is before or equal to the last available date.
 *
 * @param {Object} date - A dayjs date object representing the date to be checked for availability.
 *
 * @returns {boolean} True if the date is available; otherwise, false.
 *
 * @example
 * const dateToCheck = dayjs('2024-10-10');
 * const isAvailable = isDateAvailable(dateToCheck); // Returns true or false based on the availability criteria.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
  const isDateAvailable = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    return availableDates.includes(formattedDate) &&
           date.isAfter(today.subtract(1, 'day')) &&
           date.isBefore(lastAvailableDate.add(1, 'day'));
  };

  return (
    <motion.div variants={fadeVariants} initial="hidden" animate="visible" exit="exit">
      <h2 className="text-xl mb-4">{`${t(`DateStep.whatday`)}`}</h2>
      <div className="flex justify-between items-center mb-4">
        <button type="button" onClick={() => changeMonth(-1)} disabled={currentMonth.isSame(today, 'month')} >
          <ChevronLeft />
        </button>
        <span>{getMonthAndYear(currentMonth)}</span>
        <button type="button" onClick={() => changeMonth(1)} disabled={currentMonth.isAfter(lastAvailableDate, 'month')} >
          <ChevronRight />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-2">
        {['S', 'T', 'Q', 'Q', 'S', 'S', 'D'].map((day, index) => (
          <div key={index} className="text-center font-bold">{day}</div>
        ))}
        {calendarCells.map((day, index) => {
          if (!day) return <div key={index} />;
          const isAvailable = isDateAvailable(day);
          const isSelected = dayjs(date).isSame(day, 'day');

          return (
            <button type='button' key={index} onClick={() => { if (isAvailable) { handleDateChange(day.toDate()); setStep(3); } }} className={`p-2 border rounded transition-transform duration-300 ease-in-out transform hover:scale-105 ${ isSelected ? 'bg-teal-100 border-teal-600' : isAvailable ? 'border-gray-300 hover:bg-teal-100 hover:border-teal-600' : 'border-gray-300 text-gray-400 cursor-not-allowed opacity-50' }`} disabled={!isAvailable} >
              {day.date()}
            </button>
          );
        })}
      </div>
    </motion.div>
  );
};