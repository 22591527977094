import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { configureI18n } from './ConfigureI18n';

export const LanguageProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(['cultureCode']);
  const { i18n } = useTranslation();

  useEffect(() => {
    // Initialize with saved language or default
    const savedLanguage = cookies.cultureCode || 'pt_PT';

    // Ensure i18n is using the saved language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const handleLanguageChange = (lng) => {
      setCookie('language', lng, { path: '/', sameSite: 'strict' });
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [cookies.language, setCookie, i18n]);

  return children;
};