import { useTranslation } from "react-i18next";
import { useState, useMemo } from "react";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { AlertTriangle } from "lucide-react";

/**
 * @module Address
 * @description Component for managing the address information of a guest.
 * This component includes input fields for country selection, address, city,
 * and postal code, along with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.guest - The guest object containing address data.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.initialData - Initial data including the list of countries.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered address form fields.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Address component
 * <Address
 *   guest={guestData}
 *   handleGuestChange={handleGuestChange}
 *   initialData={initialData}
 *   requiredFields={requiredFields}
 * />
 */
export const Address = ({ guest, handleGuestChange, initialData, requiredFields, error, cS }) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);

  const countries = useMemo(() => {
    return initialData.CountryList.map((country) => ({
      ...country,
    }));
  }, [initialData.CountryList]);

  const getCountryLabel = (countryIdOrIso) => {
    if (initialData) {
      const country = initialData.CountryList.find(
        (country) =>
          country.Id === countryIdOrIso || country.IsoCode === countryIdOrIso
      );
      return country ? country.TranslatedDescription : "";
    }
  };

  const getCountryValue = () => {
    if (guest.data.CountryId) {
      return guest.data.CountryId;
    } else if (guest.data.CountryIso) {
      const country = initialData.CountryList.find(
        (c) => c.IsoCode === guest.data.CountryIso
      );
      return country ? country.Id : undefined;
    }
    return undefined;
  };

  const countryValue = getCountryValue();

  /**
   * Handles the change event when a country is selected.
   *
   * @function handleCountryChange
   * @param {Event} e - The change event from the select element representing country selection.
   * @param {number} id - The ID of the guest whose country is being changed.
   * @returns {void} No return value.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // Example usage of handleCountryChange
   * handleCountryChange(event, guest.id);
   */
  const handleCountryChange = (e, id) => {
    const selectedCountry = JSON.parse(e.target.value);
    handleGuestChange(id, "CountryId", selectedCountry.Id);
    handleGuestChange(id, "CountryIso", selectedCountry.IsoCode);
  };

  // Determine whether each field is required based on the provided validation rules
  const isRequiredCountryId = requiredFields.some(
    (field) => field.Field === "CountryId" && field.IsMandatory
  );

  const isRequiredAddress1 = requiredFields.some(
    (field) => field.Field === "Address1" && field.IsMandatory
  );

  const isRequiredTown = requiredFields.some(
    (field) => field.Field === "Town" && field.IsMandatory
  );

  const isRequiredZipCode = requiredFields.some(
    (field) => field.Field === "ZipCode" && field.IsMandatory
  );

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3">
        <div className="flex flex-col">
          <label htmlFor="genero" className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1 z-10 ${parseInt(cS) ? "OC-bg" : ``}`} >
          {`${t(`Address.country`)}`} <span className="text-gray-400 ml-2"> {isRequiredCountryId ? `( * )\u00A0` : ``} </span>
        </label>
        <Select
          suffixIcon={ <ChevronDown className={`text-gray-700 transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
          onDropdownVisibleChange={(open) => setIsOpen(open)}
          className={`w-full py-2 text-xl rounded-lg border text-gray-700 ${error.CountryId ? 'border-red-500' : 'border-gray-700'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
          style={{ height: "45.5px" }}
          onChange={(selected) => { handleGuestChange(guest.id, "CountryId", selected.value); const selectedCountry = initialData.CountryList.find( (c) => c.Id === selected.value ); if (selectedCountry) { handleGuestChange( guest.id, "CountryIso", selectedCountry.IsoCode ); } }}
          placeholder={`${t(`Address.select`)}`}
          optionLabelProp="label"
          showSearch
          dropdownStyle={{ minWidth: "260px" }}
          labelInValue
          value={ countryValue ? { value: countryValue, label: getCountryLabel(countryValue) } : undefined }
          filterOption={(input, option) => { const translatedDescription = countries.find((country) => country.dialCode === option.value) ?.TranslatedDescription || ""; return translatedDescription .toLowerCase() .includes(input.toLowerCase()); }}
          virtual={false}
        >
          {countries.map((country) => (
            <Select.Option key={country.Id} value={country.Id} label={ <div className="flex items-center"> <img src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`} alt={`${country.TranslatedDescription} flag`} className="w-6 h-4 mr-2" /> <span className="text-l">{country.dialCode}</span> </div> } >
              <div className="flex items-center">
                <img src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`} alt={`${country.TranslatedDescription} flag`} className="w-6 h-4 mr-2" />
                <span className="text-l">{country.TranslatedDescription}</span>
              </div>
            </Select.Option>
          ))}
        </Select>
        {error.CountryId && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.CountryId}</span>{" "} </div> )}
        </div>


        <div>
          <label htmlFor="address" className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >
            {`${t(`Address.address`)}`}
            <span className="text-gray-400 ml-2"> {isRequiredAddress1 ? `( * )\u00A0` : ``} </span>
          </label>
          <input name="Address1" required={isRequiredAddress1} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.Address1 || ""} type="text" id="Address1" className={`w-full px-4 py-2 rounded-lg border ${error.Address1 ? 'border-red-500' : 'border-gray-700'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />
          {error.Address1 && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.Address1}</span>{" "} </div> )}
        </div>


        <div>
          <label htmlFor="city" className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >
            {`${t(`Address.city`)}`}
            <span className="text-gray-400 ml-2"> {isRequiredTown ? `( * )\u00A0` : ``} </span>
          </label>
          <input name="Town" required={isRequiredTown} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.Town || ""} type="text" id="Town" className={`w-full px-4 py-2 rounded-lg border ${error.Town ? 'border-red-500' : 'border-gray-700'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />
          {error.Town && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.Town}</span>{" "} </div> )}
        </div>



        <div>
          <label htmlFor="postalCode" className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >
            {`${t(`Address.zipcode`)}`}
            <span className="text-gray-400 ml-2"> {isRequiredZipCode ? `( * )\u00A0` : ``} </span>
          </label>
          <input name="ZipCode" required={isRequiredZipCode} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.ZipCode || ""} type="text" id="ZipCode" className={`w-full px-4 py-2 rounded-lg border ${error.ZipCode ? 'border-red-500' : 'border-gray-700'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />
          {error.ZipCode && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.ZipCode}</span>{" "} </div> )}
        </div>
      </div>
    </>
  );
};
