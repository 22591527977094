import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import { useEffect } from "react";

/**
 * @module Nif
 * @description Component for handling the NIF (Tax Identification Number) input for a guest.
 * This component allows for the input of a guest's NIF, indicates whether it is a required field,
 * and provides validation feedback.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.guest - The guest object containing data such as NIF and nationality.
 * @param {Object} props.nifValues - The current NIF values for guests, indexed by guest ID.
 * @param {Object} props.nifErrors - The current error messages for each guest's NIF input, indexed by guest ID.
 * @param {function} props.setNifValues - Function to update the NIF values in state.
 * @param {function} props.setNifErrors - Function to update the NIF error messages in state.
 * @param {function} props.validateNifonBlur - Function to validate the NIF when the input loses focus.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered NIF input field.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Nif component
 * <Nif
 *   guest={guestData}
 *   nifValues={nifValues}
 *   nifErrors={nifErrors}
 *   setNifValues={setNifValues}
 *   setNifErrors={setNifErrors}
 *   validateNifonBlur={validateNifonBlur}
 *   requiredFields={requiredFields}
 * />
 */
export const Nif = ({ guest, handleNifChange, validateNifOnBlur, requiredFields, error, cS }) => {
  const [t] = useTranslation("global");

    // Check if the FiscalNumber field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "FiscalNumber" && field.IsMandatory);

      useEffect(()=>{
        validateNifOnBlur(guest.id,guest.data.FiscalNumber,guest.data.NationalityIso)
      },[guest.data.NationalityIso])

  return (
    <div>
    <label htmlFor="nif" className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white text-sm font-medium text-gray-700 mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >{`${t(`Nif.vatnumber`)}`}<span className="text-gray-400 ml-2">{isRequired?`( * )\u00A0`: ``}</span></label>
    <input name="FiscalNumber" required={isRequired} onChange={(e) => handleNifChange(guest.id, e.target.value)} onBlur={() => validateNifOnBlur(guest.id, guest.data.FiscalNumber, guest.data.NationalityIso)} value={guest.data.FiscalNumber || ""} type="text" id="nif" className={`w-full px-4 py-2 text-xl rounded-lg border ${error ? 'border-red-500' : 'border-gray-700'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}/>
    {error && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error}</span> </div>}
    </div>  )
}
