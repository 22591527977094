import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { motion, AnimatePresence } from "framer-motion";
import { AlertCircle, AlertTriangle, CheckCircle2, ChevronDown, ChevronRight, } from "lucide-react";
import { SubMenu } from "./SubMenu";
import { useMenuDataProcessor } from "../../../hooks/useMenuDataProcessor";


export const MainSection = ({ detailId, onMenuStateChange, posSection, setActiveSection, setIsExiting}) => {
  const [state] = useContext(AppContext);
  const [t] = useTranslation("global");
  const [cookie, setCookie] = useCookies([ "sUid", "profileKey", "GUid", "platformUid", "detailId", ]);
  const [menuData, setMenuData] = useState([]);
  const [openSections, setOpenSections] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);

  const MotionLink = motion.create(Link);

  const toggleSection = (serviceCode) => {
    setOpenSections((prev) => ({ ...prev, [serviceCode]: !prev[serviceCode] }));
  };

  useEffect(() => {
    // Notifica o pai sempre que o estado do menu muda
    onMenuStateChange?.(!!activeMenu);
  }, [activeMenu, onMenuStateChange]);

  const getCompletionIndicator = (rate) => {
    if (rate <= 40) {
      return (
        <AlertCircle className="w-5 h-5 text-red-500" aria-label="Low completion rate" />
      );
    } else if (rate <= 70) {
      return (
        <AlertTriangle className="w-5 h-5 text-yellow-500" aria-label="Medium completion rate" />
      );
    } else {
      return (
        <CheckCircle2 className="w-5 h-5 text-green-500" aria-label="High completion rate" />
      );
    }
  };

  const processMenuData = useMenuDataProcessor( posSection, setMenuData );

  useEffect(() => {
    if (state.hotelMenu && state.reservation) {
      processMenuData();
    }
  }, [state.hotelMenu, state.reservation]);

    // Lista de types que devem aparecer como rasurados
    const typesToStrikethrough = [
      "EMENU",
    ];

    // Verifica se o item.Type está na lista


  const renderMenuItem = (service, index) => {
    if (!service.subItems || service.subItems.length === 0) {
      const isStrikethrough = typesToStrikethrough.includes(service.Type);
      return (
        <MotionLink to={service.LinkTo} key={service.id || index} className="bg-white min-h-24 rounded-lg shadow p-4 w-full relative transition duration-300 ease-in-out transform hover:shadow-lg" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} >
          <div className="flex justify-center items-center h-full">
            <div className="flex items-center flex-grow justify-start pl-6">
              <span dangerouslySetInnerHTML={{ __html: service.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="text-gxp pr-4" />
              <span className={` ${typesToStrikethrough.includes(service.Type) ? "line-through" : ""}`}>{service.Description}</span>
            </div>
            {service.rate && (
              <div className="flex items-center gap-2 absolute right-7 top-5">
                {getCompletionIndicator(service.rate)}
                <span className={`text-sm text-gray-600 ${ service.rate <= 40 ? "text-red-500" : service.rate <= 70 ? "text-yellow-500" : "text-green-500" }`} >
                  {service.rate}%
                </span>
              </div>
            )}
          </div>
        </MotionLink>
      );
    }

    // Menu de navegação
    if (service.LinkType === "MENU") {
      return (
        <motion.button key={service.Id || index} onClick={() => setActiveMenu(service)} className="w-full min-h-24 rounded-lg shadow p-4 relative transition-all duration-300 ease-in-out hover:shadow-lg bg-white" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} >
          <div className="flex justify-between items-center">
            <div className="flex items-center flex-grow justify-start pl-6">
              <span dangerouslySetInnerHTML={{ __html: service.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="text-gxp pr-4" />
              <span className={`${typesToStrikethrough.includes(service.Type) ? "line-through" : ""}`} >{service.Description}</span>
            </div>
            {service.rate && (
              <div className="flex items-center gap-2 absolute right-4 top-5">
                {getCompletionIndicator(service.rate)}
                <span className={`text-sm text-gray-600 ${ service.rate <= 40 ? "text-red-500" : service.rate <= 70 ? "text-yellow-500" : "text-green-500" }`} >
                  {service.rate}%
                </span>
              </div>
            )}
            <span>
              <ChevronRight />
            </span>
          </div>
        </motion.button>
      );
    }

    // Menu dropdown (MENU-DD)
    return (

      <div>
        <motion.button key={service.Id || index} onClick={() => toggleSection(service.Code)} className={`w-full rounded-lg min-h-24 shadow p-4 relative transition-all duration-300 ease-in-out hover:shadow-lg ${ openSections[service.Code] ? "bg-gray-100" : "bg-white" }`} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} >
          <div className="flex justify-between items-center">
            <div className="flex items-center flex-grow justify-start pl-6">
            <span dangerouslySetInnerHTML={{ __html: service.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="text-gxp pr-4" />
            <span className={`${typesToStrikethrough.includes(service.Type) ? "line-through" : ""}`} >{service.Description}</span>
            </div>
            <motion.span animate={{ rotate: openSections[service.Code] ? 180 : 0 }} transition={{ duration: 0.3 }} >
              {" "}
              <ChevronDown />{" "}
            </motion.span>
          </div>
          {service.rate && (
            <div className="flex items-center gap-2 absolute right-4 top-5">
              {getCompletionIndicator(service.rate)}
              <span className={`text-sm text-gray-600 ${ service.rate <= 40 ? "text-red-500" : service.rate <= 70 ? "text-yellow-500" : "text-green-500" }`} >
                {service.rate}%
              </span>
            </div>
          )}
        </motion.button>

        <AnimatePresence>
          {openSections[service.Code] && (
            <motion.div initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: "auto", transition: { height: { duration: 0.4, ease: "easeOut" }, opacity: { duration: 0.3, delay: 0.1 }, }, }} exit={{ opacity: 0, height: 0, transition: { height: { duration: 0.3, ease: "easeIn" }, opacity: { duration: 0.2 }, }, }} className="bg-gray-300 overflow-hidden" >
              <motion.div initial={{ y: -20 }} animate={{ y: 0 }} exit={{ y: -20 }} transition={{ duration: 0.3 }} className="p-2 rounded-lg shadow-lg w-full" >
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2">
                  {service.subItems.map((subItem, index) => (
                    <motion.div key={subItem.Id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.1, duration: 0.3 }}  >
                      <MotionLink to={subItem.LinkTo} className="bg-white rounded-lg min-h-24 shadow p-4 w-full h-full relative transition duration-300 ease-in-out transform hover:shadow-lg block" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} >
                        <div className="flex justify-between items-center h-full">
                          <div className="flex flex-col items-center flex-grow justify-center text-center">
                            <span dangerouslySetInnerHTML={{ __html: subItem.IconTag, }} className="w-7 text-gxp" />
                            <span className={`${typesToStrikethrough.includes(subItem.Type) ? "line-through" : ""}`}>{subItem.Description}</span>
                          </div>
                          {subItem.rate && (
                            <div className="flex items-center gap-2 absolute right-4 top-5">
                              {getCompletionIndicator(
                                state.reservation.OnlineCheckinCompletionRate
                              )}
                              <span className={`text-sm text-gray-600 ${ state.reservation .OnlineCheckinCompletionRate <= 40 ? "text-red-500" : state.reservation .OnlineCheckinCompletionRate <= 70 ? "text-yellow-500" : "text-green-500" }`} >
                                {state.reservation.OnlineCheckinCompletionRate}%
                              </span>
                            </div>
                          )}
                        </div>
                      </MotionLink>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  return (
    menuData && menuData.length > 0 && (
      <div className="w-full max-w-7xl mx-auto px-4 my-4">
        <AnimatePresence mode="wait">
          {activeMenu ? (
            <SubMenu key="submenu" menu={activeMenu} onBack={() => { setActiveMenu(null); setActiveSection(null); }} posSection={posSection} setActiveSection={setActiveSection} setIsExiting={setIsExiting} />
          ) : (
            <motion.section key="mainmenu" className="grid grid-cols-1 gap-2" >
              {menuData.map((service, index) => (
                <motion.div key={index} initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ delay: index * 0.1 }} className="flex h-full w-full flex-col" >
                  {renderMenuItem(service, index)}
                </motion.div>
              ))}
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    )
  );
};
