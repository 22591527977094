import { useState, useEffect } from "react";
import { ReservationsDisplay } from "./Components/ReservationsDisplay";
import { useParams } from "react-router-dom";
import { getHotelPmsIdPrefix } from "../../services";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

export const Home = () => {

  //primeiros 8 digitos correspondentes ao Guid do hotel
  const { platformUid } = useParams();
  //sUid do host

  const [principalHotel, setPrincipalHotel] = useState(); // eslint-disable-line
  const [groupHotels, setGroupHotels] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [cookie, setCookie] = useCookies([ "platformUid", "sUid", "profileKey", "cultureCode" ]); // eslint-disable-line


  const findPrincipalHotel = (groupHotels) => {
    // Loop através da lista de hotéis
    for (const hotel of groupHotels) {
      // Extrai os primeiros 8 dígitos do pmsId
      const pmsIdPrefix = hotel.pmsId.slice(0, 8);
      // Verifica se o prefixo do pmsId corresponde ao platformId
      if (pmsIdPrefix === platformUid) {
        setPrincipalHotel(hotel);
        break; // Encerra o loop após encontrar o hotel correspondente
      }
    }
  };

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const data = await getHotelPmsIdPrefix(platformUid);
        const sortHotels = (hotels) => {
          return hotels.sort((a, b) => {
            // Critério 1: Hotel correspondente ao platformUid vem primeiro
            const isAMatch = a.hotelId.startsWith(platformUid);
            const isBMatch = b.hotelId.startsWith(platformUid);

            if (isAMatch !== isBMatch) {
              return isAMatch ? -1 : 1;
            }

          });
        };
        const sortedHotels = sortHotels(data);
        setGroupHotels(sortedHotels);
        findPrincipalHotel(sortedHotels);
      } catch (error) {
        console.error("Error fetching hotels:", error);
        toast.error("Unable to get hotels!");
      }
    };
    fetchHotels();
  }, [cookie.platformUid, platformUid]); // eslint-disable-line

  return (
        <main className="flex-grow overflow-y-hidden bg-gxp_bg flex flex-col w-full h-full">
          <div className="container mx-auto mt-5">
            <ReservationsDisplay reservations={reservations} groupHotels={groupHotels} setReservations={setReservations} />
          </div>
        </main>
  );
};
