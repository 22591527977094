import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { MobileHeader } from "../components/Layout/MobileHeader";
import { Footer, MobileFooter, Navbar, SidebarHotel } from "../components";

export const RootLayout = () => {
  const { pathname } = useLocation();
  const isDirect = pathname.includes("direct");

  return (
    <div className="min-h-screen flex flex-col bg-gxp_bg">
      {/* Header Section */}
      {!isDirect && (
        <>
          <div className="">
            <MobileHeader />
          </div>
          {/* <div className="max-lg:hidden">
            <Navbar />
          </div> */}
        </>
      )}

      {/* Main Content Section */}
      <div className={`flex-1 flex ${isDirect ? "" : "max-md:pb-20"}`}>
        {/* {!isDirect && (
          <aside className="max-lg:hidden w-64 flex-shrink-0">
            <SidebarHotel />
          </aside>
        )} */}

        {/* Main Content Area */}
        <main className="flex-1 flex flex-col overflow-auto w-full">
          <div className="flex-1 w-full">
            <Outlet />
          </div>

          {!isDirect && (
            <div className="max-lg:hidden mt-auto">
              <Footer />
            </div>
          )}
        </main>
      </div>

      {/* Mobile Footer */}
      {!isDirect && (
        <div className="">
          <MobileFooter />
        </div>
      )}
    </div>
  );
};