import { useContext } from "react";
import { useCookies } from "react-cookie";
import { AppContext } from "../context/AppContext";

export const useMenuDataProcessor = (posSection, setMenuData) => {
  const [cookie] = useCookies(["platformUid", "currentHotel", "GUid", "reservation", "detailId", "sUid"]);
  const [state, dispatch] = useContext(AppContext);

  const processMenuData = () => {

    if (!state.hotelMenu || state.hotelMenu.length === 0 || !state.reservation) {
      return [];
    }

    const mainMenuItems = state.hotelMenu.filter((item) =>
      item.Section?.includes(posSection)
    );

    const sortedMenuData = mainMenuItems.sort(
      (a, b) => a.SortOrder - b.SortOrder
    );

    const groupedMenu = sortedMenuData.reduce(
      (acc, item) => {
        // Process link types
        if (item.Type === "WEBTEXT" || item.Type === "WEB_TEXT") {
          item.LinkTo = `/hotel/${cookie.platformUid}/${cookie.detailId}/webtext/${item.Keys[0] || ""}`;
        } else if (item.Type === "EXTERNAL") {
          item.LinkTo = item.TargetUrl;
        } else if (item.Type === "TABLE-RESERVATION") {
          if (item.Keys.length === 1 && item.Keys[0] !== "0") {
            item.LinkTo = `/hotel/${cookie.platformUid}/${cookie.detailId}/treservation/${cookie.sUid}/${item.Keys[0] || ""}`;
          } else {
            item.LinkTo = `/hotel/${cookie.platformUid}/${cookie.detailId}/treservationmenu/${cookie.sUid}/${item.Keys.join("-")}`;
          }
        } else if (item.Type === "RESERVATION-DETAILS") {
          item.LinkTo = `/hotel/${cookie.platformUid}/${cookie.detailId}/reservation`;
        } else if (item.Type === "RESERVATION-QRCODE") {
          item.LinkTo = `/hotel/${cookie.platformUid}/${cookie.detailId}/qrcode`;
        } else if (item.Type === "RESERVATION-CHECKIN") {
          item.LinkTo = `/hotel/${cookie.platformUid}/${cookie.detailId}/checkin/${cookie.sUid}/${state.reservation.resno}/0`;
          item.rate = state.reservation.OnlineCheckinCompletionRate;
        } else if (item.Type === "RESERVATION-CHECKOUT") {
          item.LinkTo = `/hotel/${cookie.platformUid}/${cookie.detailId}/checkout`;
        } else if (item.Type === "RESERVATION-MAINTENANCE-REQUEST") {
          item.LinkTo = `/hotel/${cookie.platformUid}/${cookie.detailId}/maintenancerequest`;
        } else if (item.Type === "EMENU" && (item.Keys.length > 1 || item.Keys[0] === "0")) {
          item.LinkTo = `/hotel/${cookie.platformUid}/${cookie.detailId}/emenu-menu/${cookie.sUid}/${item.Keys.join("-")}`;
        }

        // Group items
        if (item.ParentCode === "" && item.Active === true) {
          const mainItem = { ...item, subItems: [] };

          if (acc._unassignedSubItems && acc._unassignedSubItems[item.Code]) {
            mainItem.subItems = mainItem.subItems.concat(
              acc._unassignedSubItems[item.Code]
            );
            delete acc._unassignedSubItems[item.Code];
          }

          acc.items = acc.items || [];
          acc.items.push(mainItem);
        } else if (item.Active === true) {
          const parentItem = acc.items?.find(
            (parent) => parent.Code === item.ParentCode
          );

          if (parentItem) {
            parentItem.subItems.push(item);
          } else {
            acc._unassignedSubItems = acc._unassignedSubItems || {};
            acc._unassignedSubItems[item.ParentCode] =
              acc._unassignedSubItems[item.ParentCode] || [];
            acc._unassignedSubItems[item.ParentCode].push(item);
          }
        }

        return acc;
      },
      { items: [], _unassignedSubItems: {} }
    );

    // Sort sub-items
    groupedMenu.items?.forEach((item) => {
      if (item.subItems?.length > 0) {
        item.subItems.sort((a, b) => a.SortOrder - b.SortOrder);
      }
    });

    setMenuData(groupedMenu.items || []);
  };

  return processMenuData;
};