import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { FreeMode } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../../../../index.css";
import { SliderCard } from "./SliderCard";
import { useState } from "react";

export const Slider = ({ data, title, type }) => {
  const [slidesPerView, setSlidesPerView] = useState(1.5);

  return (
    <section className="flex flex-wrap justify-between mb-4">
      <div className="py-3 border-gray-200  dark:bg-gray-800 dark:border-gray-700 max-w-full">
        <h5 className="pb-3 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        <Swiper spaceBetween={10} slidesPerView={slidesPerView} autoplay={{ delay: 2500, disableOnInteraction: false, pauseOnMouseEnter: true, }} breakpoints={{ 320: { slidesPerView: 1.5, spaceBetween: 10 }, 640: { slidesPerView: 2.5, spaceBetween: 15 }, 768: { slidesPerView: 3.5, spaceBetween: 15 }, 1024: { slidesPerView: 4.5, spaceBetween: 20 }, 1280: {  slidesPerView: 5.5, spaceBetween: 20 } }} loop={true} modules={[FreeMode, Autoplay]} className="mySwiper" >
          {data.map((element, index) => (
              <SwiperSlide className="overflow-hidden" key={index} >
                <SliderCard element={element} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
  );
};
