import React from "react";

/**
 * @module RestaurantHeader
 * @description Renders the header for the restaurant reservation component,
 * displaying the service logo passed as a prop. This component is meant
 * to visually represent the restaurant associated with the reservation.
 *
 * @param {Object} props - The component properties.
 * @param {Object} props.service - The service object containing information
 * about the restaurant.
 * @param {string} props.service.logoUrl - The URL of the restaurant logo
 * to be displayed.
 *
 * @returns {JSX.Element} The restaurant header component containing the logo.
 *
 * @example
 * <RestaurantHeader service={{ logoUrl: "https://example.com/logo.png" }} />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const RestaurantHeader = ({service}) => {
  return (
    <div className="text-center mb-4 flex flex-col items-center">
      <img src={service.logoUrl} alt="Service Logo" className="h-30 w-30"/>
    </div>
  );
};
