import { useState, useContext, useEffect } from "react";
import { Send } from "lucide-react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import { useCookies } from "react-cookie";
import { getReservationByDetailId, maintenanceRequest } from "../../services";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { HeadersPage } from "../../components/Layout/HeadersPage";

export const MaintenanceRequest = () => {
  const [request, setRequest] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [state, dispatch] = useContext(AppContext); // eslint-disable-line
  const [t] = useTranslation("global");
  const [reservation, setReservation] = useState(state.reservation);
  const [cookie] = useCookies(["sUid", "currentHotel"]);
  const { detailId } = useParams();
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!request.trim()) return;

    const req = { sUid: cookie.sUid, detailId: detailId, info: request };

    try {
      setIsSending(true);
      await maintenanceRequest(cookie.currentHotel, req);
      setSubmitted(true);
      setRequest("");
      toast.success(t(`MaintenanceRequest.success`));
    } catch (error) {
      toast.error(t(`HostFormComplete.formfail`));
      console.error("Maintenance request error:", error);
    } finally {
      setIsSending(false);
    }
  };

  const fetchReservationByDetailId = async () => {
    if (cookie.currentHotel?.subscriptionKey && detailId) {
      try {
        const results = await getReservationByDetailId(
          detailId,
          cookie.currentHotel,
          cookie.sUid
        );
        setReservation(results[0]);
        dispatch({ type: "SET_USER_RESERVATION", payload: results[0] });
      } catch (error) {
        console.log(error);
        toast.error("Unable to get reservations!");
      }
    }
  };

  useEffect(() => {
    if (!reservation) {
      fetchReservationByDetailId();
    }
  }, [detailId]); // eslint-disable-line

  return (
    <>
      {reservation && (
        <div className={`${ state.darkMode ? "" : "page_bg_color" } antialiased dark:bg-gray-900 flex flex-col h-full`} >
          <section className="bg-white shadow-md overflow-hidden flex-grow">
            <HeadersPage title={`${t(`MaintenanceRequest.maintenance`)}`} room={reservation?.room} />
            <main className="container mx-auto ">
              <div className="bg-white p-6">
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="maintenance-request" className="block text-gray-700 font-medium mb-2" > {t("MaintenanceRequest.maintenancerequest")} </label>
                    <textarea id="maintenance-request" rows={5} className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500" value={request} onChange={(e) => setRequest(e.target.value)} placeholder={`${t(`MaintenanceRequest.description`)}`} ></textarea>
                  </div>
                  <button type="submit" disabled={isSending} className="bg-blue-600 text-white px-4 py-2 cursor-pointer w-40 h-10 rounded-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center" >
                    {isSending ? ( <Loader /> ) : ( <> <Send size={18} className="mr-2" />{" "} {`${t(`MaintenanceRequest.send`)}`} </> )}
                  </button>
                </form>
                {submitted && (
                  <div className="mt-4 p-4 bg-green-100 border-l-4 border-green-500 text-green-700 rounded-lg">
                    <p>{`${t(`MaintenanceRequest.success`)}`}</p>
                  </div>
                )}
              </div>
            </main>
          </section>
        </div>
      )}
    </>
  );
};
