import { useEffect, useContext, useState, useRef, useCallback, useMemo, } from "react";
import { Home } from "lucide-react";
import { useCookies } from "react-cookie";
import { AppContext } from "../../context/AppContext";
import { getHostProfile, getHotelMenu } from "../../services";
import { toast } from "react-toastify";
import { useLocation, Link, useParams } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Loader } from "./components/Loader/Loader.js";
import { useMenuDataProcessor } from "../../hooks/useMenuDataProcessor";


export const MobileFooter = () => {
  const [activeSubitems, setActiveSubitems] = useState(null);
  const { platformUid} = useParams()
  const footerRef = useRef(null);
  const subfooterRef = useRef(null);
  const [menuData, setMenuData] = useState([]);
  const [cookie, setCookie] = useCookies([ "sUid", "profileKey", "GUid", "platformUid", "detailId", "guest",'isProfileLoaded','currentHotel', 'initialLink' ]);
  const [state, dispatch] = useContext(AppContext);
  const { pathname } = useLocation();
  const { isMobile } = useWindowSize();
  const posSection = "FOOTER"
  const isHome = pathname.includes("/home");
  const processMenuData = useMenuDataProcessor( posSection, setMenuData );
  const [isLoading, setIsLoading] = useState(true);

  // Reset menu data when hotel changes
  useEffect(() => {
    setMenuData([]); // Clear existing menu data
    setIsLoading(true);
  }, [cookie.currentHotel?.subscriptionKey]);

  const homeItem = useMemo(() => (
    {
      Description: "HOME", Active: true, IconTag: Home, LinkTo: cookie.initialLink, SortOrder: 0
    }
), [cookie.platformUid, cookie.sUid]); // dependências

const toggleSubitems = useCallback((item, event) => {
  event.stopPropagation();
  // Agora comparamos com o Description do item
  setActiveSubitems((current) => (current?.Description === item.Description ? null : item));
}, []);

 // Fetch hotel menu
 useEffect(() => {
  if (cookie.currentHotel?.subscriptionKey && cookie.currentHotel?.defaultCultureCode) {
    setIsLoading(true);
    getHotelMenu(cookie.currentHotel.defaultCultureCode, cookie.currentHotel.subscriptionKey)
      .then((result) => {
        dispatch({ type: "SET_HOTEL_MENU", payload: result });
      })
      .catch((error) => {
        toast.error("Unable to get hotel Menu!");
        setIsLoading(false);
      });
  }
}, [cookie.currentHotel?.subscriptionKey, cookie.currentHotel?.defaultCultureCode, platformUid]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Verifica se o clique foi fora do footer E do subfooter
      if ( footerRef.current && !footerRef.current.contains(event.target) && subfooterRef.current && !subfooterRef.current.contains(event.target) ) {
        setActiveSubitems(null); }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, []);

  useEffect(() => {
    setActiveSubitems(null);
  }, [pathname]);

  /**
   * UseEffect that search host info using shortuid and hotel subscriptionKey
   */
  useEffect(() => {
    const subscriptionKey = cookie.currentHotel?.subscriptionKey || cookie.currentHotel?.subscriptionKey;
    const shouldFetchProfile = subscriptionKey && cookie.sUid && !pathname.includes("/checkindirect") && !cookie.isProfileLoaded;

    if (shouldFetchProfile) {
      getHostProfile(cookie.sUid, cookie.profileKey, subscriptionKey)
        .then((data) => {
          dispatch({ type: "SET_USER_DETAILS", payload: data });

          // Update cookies with user data
          const cookieOptions = { path: "/" };
          setCookie('isProfileLoaded', true, cookieOptions);
          setCookie("profileKey", data.ProfileKey, cookieOptions);
          setCookie("GUid", data.Uid, cookieOptions);
          setCookie("guest", JSON.stringify(data), cookieOptions);
        })
        .catch((error) => {
          console.error("Failed to get host profile:", error);
          toast.error("Unable to get host!");
        });
    }
  }, [cookie.sUid, cookie.profileKey, platformUid, cookie.isProfileLoaded, state.currentHotel, cookie.currentHotel]);

  // Process menu data when hotel menu or reservation changes
  useEffect(() => {
    if (state.hotelMenu && state.reservation) {
      processMenuData();
      setMenuData(prevMenu => {
        const menuWithoutHome = prevMenu.filter(item => item.Description !== "HOME");
        return [...menuWithoutHome, homeItem].sort((a, b) => (a.SortOrder ?? 999) - (b.SortOrder ?? 999));
      });
      setIsLoading(false);
    }
  }, [state.hotelMenu, state.reservation, homeItem, platformUid]);

      // Lista de types que devem aparecer como rasurados
      const typesToStrikethrough = [
        "EMENU",
      ];


      const footerContent = useMemo(() => {
        if (isLoading || menuData.length <= 1) {
          return (
            <footer className="bg-white fixed bottom-0 left-0 right-0 shadow-lg border-t border-gray-200 z-20 h-20">
              <div className="flex flex-col justify-center items-center py-4 h-full w-full">
                <Loader className="text-gray-500 h-16 w-16" />
              </div>
            </footer>
          );
        }

        return (
          menuData.length > 1 && (
            <footer ref={footerRef} className={`bg-white fixed bottom-0 left-0 right-0 shadow-lg border-t border-gray-200 z-20 h-20 w-full`}>
              <div className="relative  w-full flex flex-col items-center">
                <div className={`w-full flex flex-col items-center z-10 absolute bottom-full left-0 right-0 bg-white rounded-t-2xl shadow-inner overflow-hidden transition-all duration-500 ease-in-out text-gray-500 transform ${activeSubitems ? "max-h-28 opacity-100 translate-y-0" : "max-h-0 opacity-0 translate-y-8" } `} >
                  <div ref={subfooterRef} className={` w-full lg:max-w-4xl grid auto-cols-fr grid-flow-col gap-2 p-2 transition-all duration-500 ease-in-out transform ${activeSubitems ? "opacity-100 translate-y-0 delay-100" : "opacity-0 -translate-y-4" } `} >
                    {menuData.find(item => item.Description === activeSubitems?.Description)?.subItems?.map((subitem, index) => (
                      <Link to={subitem?.LinkTo} onClick={() => setActiveSubitems(null)} key={`${subitem.Description}-${index}`} className={` flex flex-col items-center mx-4 text-center text-gray-500 transform transition-all duration-300 ease-in-out hover:scale-105 hover:text-gray-700 `} >
                        {subitem.IconTag && (
                          <span dangerouslySetInnerHTML={{ __html: subitem.IconTag?.replace(/<i /, '<i style="font-size: 24px" ') }} className="mb-1" />
                        )}
                        <span className={` ${typesToStrikethrough.includes(subitem.Type) ? "line-through" : ""} text-xs transition-all duration-300 `}>
                          {subitem.Description}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
                <nav className="grid auto-cols-fr grid-flow-col py-4 w-full lg:max-w-4xl">
                  {menuData.map((item, index) => (
                    <div key={index} className="flex flex-col items-center">
                      {item.Description === "HOME" ? (
                        <Link to={item.LinkTo} className="flex flex-col items-center transition-all duration-300 ease-in-out text-gray-500 hover:text-gray-700" >
                          <item.IconTag className="w-6 h-6 mb-1" />
                          <span className={` ${typesToStrikethrough.includes(item.Type) ? "line-through" : ""} text-xs `}>
                            {item.Description}
                          </span>
                        </Link>
                      ) : (
                        <button disabled={isHome} className={` flex flex-col items-center transition-all duration-300 ease-in-out text-gray-500 ${isHome ? "opacity-50" : "opacity-100 hover:text-gray-700"} `} onClick={(e) => item.subItems && toggleSubitems(item, e)} >
                          {item.IconTag && (
                            <span dangerouslySetInnerHTML={{ __html: item.IconTag?.replace(/<i /, '<i style="font-size: 24px" ') }} className="mb-1" />
                          )}
                          <span className={` ${typesToStrikethrough.includes(item.Type) ? "line-through" : ""} text-xs `}>
                            {item.Description}
                          </span>
                        </button>
                      )}
                    </div>
                  ))}
                </nav>
              </div>
            </footer>
          )
        );
      }, [ cookie.currentHotel?.subscriptionKey, menuData, isLoading, activeSubitems, isHome, toggleSubitems, platformUid ]);

      return footerContent;
     };