import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import global_en_us from "../../../translations/en_us/global.json";
import global_pt_pt from "../../../translations/pt_pt/global.json";

export const configureI18n = (initialLanguage = 'pt_PT') => {
  i18next
    .use(initReactI18next)
    .init({
      interpolation: { escapeValue: true },
      lng: initialLanguage,
      fallbackLng: 'pt_PT',
      resources: {
        en_US: {
          global: global_en_us,
        },
        pt_PT: {
          global: global_pt_pt,
        },
      },
    });

  return i18next;
};