import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import onlineCheckInDesktop from "../../assets/images/onlineCheckInDesktop.jpeg";
import onlineCheckIn from "../../assets/images/onlineCheckIn.jpeg";
import { Loading } from "../Loading";
import { useWindowSize } from "../../hooks/useWindowSize";
import { QRCode } from "antd";
import { getKeypassKey, getReservationByDetailId } from "../../services";
import { HeadersPage } from "../../components/Layout/HeadersPage";

export const QrCode = () => {
  const [keypassKeyString, setKeypassKeyString] = useState("");
  const { detailId } = useParams();
  const [cookie] = useCookies(["sUid", "currentHotel"]);
  const [state] = useContext(AppContext); // eslint-disable-line
  const [t] = useTranslation("global");
  const [reservation, setReservation] = useState();
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);

  const getKeypassKeyString = async () => {
    try {
      setIsLoading(true);
      const result = await getKeypassKey( detailId, cookie.currentHotel, cookie.sUid );
      setKeypassKeyString(result);
    } catch (error) {
      console.log(error);
      toast.error("Unable to get KeyPass Key!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (detailId && cookie.sUid && cookie.currentHotel) {
      getKeypassKeyString();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    getReservationByDetailId(detailId, cookie.currentHotel, cookie.sUid)
      .then((data) => {
        setReservation(data[0]);
      })
      .catch((error) => {
        console.error("App: Error fetching checkout info:", error);
        toast.error("App: Unable to get checkout info!");
      });
  }, [detailId]); // eslint-disable-line

  return (
    <>
      {reservation && (
        <div className={`${ state.darkMode ? "" : "bg-gxp_bg" } antialiased dark:bg-gray-900 h-full w-full`} >
            <section className="h-full w-full">
            <HeadersPage title={`${t(`QrCode.qrcode`)}`} room={reservation?.room}/>

              <div className="flex flex-col justify-center items-center mt-5">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <QRCode value={keypassKeyString || ""} status={isLoading ? "loading" : "active"} size={256} errorLevel="H" bordered />
                  </>
                )}
              </div>
            </section>
        </div>
      )}
    </>
  );
};
