import { AppContext } from "../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { Navbar, Footer, Sidebar } from "../../components";
import { useTranslation } from "react-i18next";
import { RestaurantCard } from "./components/RestaurantCard";
import { RestaurantAndBarsOrders } from "./components/RestaurantAndBarsOrders";
import { BarsCard } from "./components/BarsCard";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getHotelMenu, getHotelById } from "../../services";
import { useCookies } from "react-cookie";

export const RestauranteAndBares = () => {
  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global")
  const {hotelId} = useParams()
  const [restaurants, setRestaurants] = useState([])
  const [bars, setBars] = useState([])
  const [cookie, setCookie] = useCookies(['platformUid']); // eslint-disable-line
  const [services, setServices] = useState([])

console.log(state.currentHotel)
console.log(services)


  useEffect(() => {
    getHotelMenu( state.currentHotel.defaultCultureCode,state.currentHotel.subscriptionKey).then((data) => {
       setServices(data)
    }).catch(error => {
      console.error("Error fetching hotel info:", error);
      toast.error("aaa Unable to get hotel info!");
    });
  }, [state.currentHotel]);

  useEffect(()=>{
  getHotelById(hotelId).then((result)=>{
    const data = result
    dispatch({type:"SET_CURRENT_HOTEL", payload:data})
  }).catch(error=>{
  toast.error("Unable to get hotel info!")
  })
  },[hotelId]) // eslint-disable-line

  return (
    <div className={`antialiased ${ state.darkMode ? "" : "page_bg_color" } dark:bg-gray-900`} >
      <Navbar />
      <div className="h-auto pt-[61px] md:ml-[255px]">
        <div className="mx-4 pt-4">
          <section className="mb-4 ">
              <div className={`px-5 py-3 ${state.darkMode?'':'sections_title_color sections_bg_color sections_border_color'} border border-gray-300 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
                <h5 className="pb-3 text-2xl font-bold tracking-tight dark:text-white">{t("restaurants")}</h5>
                <div className="flex flex-wrap" >
                  {restaurants && (
                    <>
                   {restaurants.map((restaurant)=>(
                  <RestaurantCard key={restaurant.serviceId} serviceName={restaurant.serviceName} serviceImage={restaurant.serviceImage} serviceStatus={restaurant.serviceStatus} hotelId={hotelId} serviceId={restaurant.serviceId} />
                  ))}
                  </>)}
                </div>
              </div>
            </section>
            <section className="mb-4 ">
              <div className={`px-5 py-3 ${state.darkMode?'':'sections_title_color sections_bg_color sections_border_color'} border border-gray-300 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
                <h5 className="pb-3 text-2xl font-bold tracking-tight dark:text-white">{t("bars")}</h5>
                <div className="flex flex-wrap" >
                {bars && (
                    <>
                   {bars.map((bar)=>(
                  <BarsCard key={bar.serviceId} serviceName={bar.serviceName} serviceImage={bar.serviceImage} serviceStatus={bar.serviceStatus} hotelId={hotelId} serviceId={bar.serviceId} />
                  ))}
                  </>)}
                </div>
              </div>
            </section>
            <section className="mb-4 ">
              <div className={`px-5 py-3 ${state.darkMode?'':'sections_title_color sections_bg_color sections_border_color'} border border-gray-300 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
                <h5 className="pb-3 text-2xl font-bold tracking-tight dark:text-white">{t("your_orders")}</h5>
                <div className="flex flex-wrap md:space-x-4" >
                  {/* <RestaurantAndBarsOrders /> */}
                </div>
              </div>
            </section>
        </div>
        <Footer />
      </div>
    </div>
  );
};
