import { Link } from "react-router-dom"
import { AnimatePresence, motion } from "framer-motion";
import { AlertCircle, AlertTriangle, CheckCircle2 } from "lucide-react";

export const SliderCardHotel = ({subItem, index}) => {

  const getCompletionIndicator = (rate) => {
    if (rate <= 40) {
      return ( <AlertCircle className="w-5 h-5 text-red-500" aria-label="Low completion rate" /> );
    } else if (rate <= 70) {
      return (
        <AlertTriangle className="w-5 h-5 text-yellow-500" aria-label="Medium completion rate" /> );
    } else {
      return (
        <CheckCircle2 className="w-5 h-5 text-green-500" aria-label="High completion rate" />
      );
    }
  };

    // Lista de types que devem aparecer como rasurados
    const typesToStrikethrough = [
      "EMENU",
    ];


  return (
      <motion.div key={index} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, delay: index * 0.1 }} >
        <Link to={subItem.LinkTo} className="flex flex-col bg-white h-40 w-50 rounded-lg shadow p-4 w-full relative transition duration-300 ease-in-out transform hover:shadow-lg" >
          <div className="flex justify-between items-center h-full">
            <div className="flex flex-col items-center flex-grow justify-center text-center">
              <span
                dangerouslySetInnerHTML={{
                  __html: subItem.IconTag.replace(/<i /, '<i style="font-size: 25px" ')
                }}
                className="text-gxp mb-2"
              />
              <span className={` ${typesToStrikethrough.includes(subItem.Type) ? "line-through" : ""}`}>{subItem.Description}</span>
            </div>
            {subItem.rate && (
              <div className="flex items-center gap-2 absolute right-7 top-5">
                {getCompletionIndicator(subItem.rate)}
                <span className={`text-sm text-gray-600 ${ subItem.rate <= 40 ? "text-red-500" : subItem.rate <= 70 ? "text-yellow-500" : "text-green-500" }`} >
                  {subItem.rate}%
                </span>
              </div>
            )}
          </div>
        </Link>
      </motion.div>
  )
}
