import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { getHotelInitialData } from "../../../../services";

/**
 * @module Phone
 * @description Component for handling a guest's phone number input, including country dial code selection.
 * Provides a dropdown for selecting a dial code and a text input for the phone number.
 * The component formats the phone number with the selected dial code.
 *
 * @param {Array<string>} mainDialCodes - The list of primary dial codes.
 * @param {Object} formData - The form data that holds the guest's details.
 * @param {function} setFormData - Function to update form data.
 *
 * @returns {JSX.Element} The rendered phone number input field with dial code selection.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Phone component
 * <Phone
 *   mainDialCodes={["+1", "+44", "+55"]}
 *   formData={formData}
 *   setFormData={setFormData}
 * />
 */
export const Phone = ({mainDialCodes, formData, setFormData }) => {
  const [selectedDialCode, setSelectedDialCode] = useState(""); // State to store the selected dial code
  const [phoneNumber, setPhoneNumber] = useState(""); // State to store the phone number
  const [isDialCodeSelected, setIsDialCodeSelected] = useState(false); // State to check if a dial code has been selected
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to check if the dropdown is open
  const [t] = useTranslation("global");
  const [initialData, setInitialData] = useState();
  const [cookie, setCookie] = useCookies(["currentHotel"]); // eslint-disable-line

  /**
   * @function handleDialCodeChange
   * @description Handles changes to the dial code selection.
   * This function is triggered when the user selects a new dial code from the dropdown.
   * It updates the local state with the new dial code and formats the phone number
   * to include the selected dial code, ensuring the previous dial code is removed
   * from the phone number if it exists.
   *
   * @param {Event} e - The event object from the change event of the select element.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // When a user selects a new dial code from the dropdown:
   * handleDialCodeChange(event);
   *
   * // This will result in updating the guest's phone number to include the new dial code,
   * // formatted as "dialCode phoneNumber", without any previous dial code.
   *
   * @see handleGuestChange
   */
  const handleDialCodeChange = (e) => {
    const dialCode = e.target.value; // Get the selected dial code
    setSelectedDialCode(dialCode); // Update the dial code in state
    setIsDialCodeSelected(true); // Mark that the dial code has been selected
  };

/**
 * @function dialCodeIndex
 * @description This constant maps each dial code from the `mainDialCodes` array
 * to its corresponding index using the `reduce` method. The result is an object
 * where each key is a dial code and its value is the index of that dial code
 * in the original array.
 *
 * @param {Array<string>} mainDialCodes - An array of dial codes that need to be
 * mapped to their indices.
 *
 * @returns {Object} An object where the keys are dial codes and the values are
 * their respective indices in the `mainDialCodes` array.
 *
 * @example
 * // Given an array of dial codes
 * const mainDialCodes = ['+1', '+44', '+91'];
 * const dialCodeIndex = mainDialCodes.reduce((acc, code, index) => {
 *   acc[code] = index;
 *   return acc;
 * }, {});
 * // Result: { '+1': 0, '+44': 1, '+91': 2 }
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
const dialCodeIndex = mainDialCodes.reduce((acc, code, index) => {
  acc[code] = index;
  return acc;
}, {});

/**
 * @function sortCountriesByDialCode
 * @description Sorts the list of countries based on the dial codes.
 * Countries that have their dial codes present in the `mainDialCodes` list are prioritized,
 * and those without a matching dial code are placed at the end of the list.
 *
 * @param {Array<Object>} CountryList - The list of countries to be sorted. Each country object contains an `IsoCode3LetterDocumentReader`.
 * @param {Object} dialCodeIndex - An object that maps dial codes to their index in the `mainDialCodes` array.
 *
 * @returns {Array<Object>} The sorted array of country objects, with priority given to countries with matching dial codes.
 *
 * @example
 * const sortedCountries = CountryList?.sort((a, b) => {
 *   const aDialCode = a.IsoCode3LetterDocumentReader;
 *   const bDialCode = b.IsoCode3LetterDocumentReader;
 *   const aIndex = dialCodeIndex[aDialCode] !== undefined ? dialCodeIndex[aDialCode] : Infinity;
 *   const bIndex = dialCodeIndex[bDialCode] !== undefined ? dialCodeIndex[bDialCode] : Infinity;
 *   return aIndex - bIndex; // Countries with matching dial codes appear first
 * });
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */  const sortedCountries = initialData?.CountryList?.sort((a, b) => {
    const aDialCode = a.IsoCode3LetterDocumentReader;
    const bDialCode = b.IsoCode3LetterDocumentReader;

    const aIndex = dialCodeIndex[aDialCode] !== undefined ? dialCodeIndex[aDialCode] : Infinity;
    const bIndex = dialCodeIndex[bDialCode] !== undefined ? dialCodeIndex[bDialCode] : Infinity;

    return aIndex - bIndex; // Ordena com base nos índices, colocando os que não estão nos mainDialCodes no final
  });

    /**
   * @function useEffectFetchHotel
   * @description Fetches initial hotel data when the hotel prop changes.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, [hotel]);
   */
    useEffect(() => {
      if (cookie.currentHotel) {
        getHotelInitialData(cookie.currentHotel)
          .then((data) => {
            setInitialData(data.InitialData);
          })
          .catch((error) => {
            console.error(`${t(`OnlineCheckIn.errorhotel`)}`, error);
            toast.error(`${t(`OnlineCheckIn.errorhotel`)}`);
          });
      }
    }, []); // eslint-disable-line

    /**
 * @function useEffectUpdatePhoneNumber
 * @description This `useEffect` hook updates the form data whenever the phone number or selected dial code changes.
 * It combines the selected dial code and the phone number into a single string and updates the `GuestPhone1` field in the form data.
 *
 * @param {string} selectedDialCode - The dial code selected by the user (e.g., +1, +44).
 * @param {string} phoneNumber - The phone number input by the user.
 * @param {Object} formData - The current form data containing the guest's details.
 * @param {function} setFormData - A function to update the form data state.
 *
 * @returns {void} This hook does not return a value, but updates the formData state when dependencies change.
 *
 * @example
 * useEffect(() => {
 *   setFormData({ ...formData, GuestPhone1: `${selectedDialCode} ${phoneNumber}` });
 * }, [phoneNumber, selectedDialCode]);
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
    useEffect(()=>{
      setFormData({ ...formData, GuestPhone1: `${selectedDialCode} ${phoneNumber}` })
    },[phoneNumber, selectedDialCode])

  return (
    <>
    { initialData && mainDialCodes &&
      <div className="flex w-full">
        <div className="flex w-1/3 items-end">
          <select onChange={(e)=>{handleDialCodeChange(e);setIsDropdownOpen(false)}} name="DialCode" className="w-full px-4 py-2 rounded-l border border-gray-700 focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out appearance-none" value={isDialCodeSelected ? selectedDialCode : ""} onSelect={() => setIsDropdownOpen(false)} onBlur={() => setIsDropdownOpen(false)} onClick={() => setIsDropdownOpen(!isDropdownOpen)} >
            <option value=""  >{`${t(`select`)}`}</option>
            {sortedCountries.map((country) => {
                const dialCode = initialData.DialCodes[country.IsoCode] || "";
                return (
                  dialCode && (
                    <option key={country.Id} value={dialCode}>
                      {isDropdownOpen
                        ? `${country.TranslatedDescription} ${dialCode}`
                        : dialCode}
                    </option>
                  )
                );
              })}
          </select>
        </div>
        <div className="w-full">
          <label htmlFor="telefone" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 translate-x-3 text-center justify-center bg-white text-sm font-medium text-gray-700 mb-1" >{`${t(`Phone.phonenumber`)}`}</label>
          <input name="Phone" onChange={(e)=>setPhoneNumber(e.target.value )} value={phoneNumber || ""} type="tel" id="telefone" className="w-full px-4 py-2 rounded-r border border-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-xl transition duration-150 ease-in-out" />
        </div>
      </div>
}
    </>
  );
};
