import { Navbar, Sidebar, Footer } from "../../components";
import { LanguageDropdown } from "./components/LanguageDropdown";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import { useContext } from "react";

export const Settings = () => {

  const [t] = useTranslation("global");
  const [state] = useContext(AppContext);


  return (
    <div className="antialiased bg-gray-100 dark:bg-gray-900 flex flex-col">
      <Navbar />
      <main className={`${state.darkMode?'':'page_bg_color'} pt-[61px] md:ml-[255px] h-screen flex-grow`}>
        <section className="mx-4 pt-4">
          <div className={`${state.darkMode?'':'sections_bg_color sections-border-color'} px-5 py-3 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
            <h5 className={`${state.darkMode?'':'sections_title_color'} pb-1 text-2xl font-bold tracking-tight dark:text-white`}> {t("settings")} </h5>
          </div>
        </section>
        <section className="mx-4 pt-2">
          <div className={`${state.darkMode?'':'sections_bg_color sections-border-color'} px-5 py-3 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
            <p className={`${state.darkMode?'':'sections_title_color'} pb-1 text-xl font-bold tracking-tight dark:text-white`}> {t("account_settings")} </p>
            <div className={`${state.darkMode?'':'sections_text_color'} flex justify-between items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white group`}>
              <div className="flex">
                <svg className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m13 19 3.5-9 3.5 9m-6.125-2h5.25M3 7h7m0 0h2m-2 0c0 1.63-.793 3.926-2.239 5.655M7.5 6.818V5m.261 7.655C6.79 13.82 5.521 14.725 4 15m3.761-2.345L5 10m2.761 2.655L10.2 15" />
                </svg>
                <span className="flex ml-3 text-left text-lg whitespace-nowrap"> {t("language")} </span>
              </div>
              <div className={"flex justify-end "}>
                <LanguageDropdown />
              </div>
            </div>
          </div>
        </section>
        <section className="mx-4 pt-2">
          <div className={`${state.darkMode?'':'sections_bg_color sections-border-color'} px-5 py-3 border rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 max-w-full`}>
            <p className={`${state.darkMode?'':'sections_title_color'} pb-1 text-xl font-bold tracking-tight text-gray-900 dark:text-white`}> {t("global_settings")} </p>
            <div className="flex justify-between items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white group">
              {/* <div className="flex">
                <svg className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m13 19 3.5-9 3.5 9m-6.125-2h5.25M3 7h7m0 0h2m-2 0c0 1.63-.793 3.926-2.239 5.655M7.5 6.818V5m.261 7.655C6.79 13.82 5.521 14.725 4 15m3.761-2.345L5 10m2.761 2.655L10.2 15" />
                </svg>
                <span className="flex ml-3 text-left text-lg whitespace-nowrap"> {t("theme")} </span>
              </div> */}
              {/* <div className={"flex justify-end "}>
                <LanguageDropdown />
              </div> */}
            </div>
          </div>
        </section>
        <Footer className="align-bottom" />
      </main>
    </div>
  );
};
