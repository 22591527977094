import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

/**
 * @module GuestStep
 * @description A React component that allows users to select the number of guests for a reservation.
 * It displays buttons for each guest number, allowing the user to click and set the number of guests.
 * The component uses animations for transitions and integrates with the internationalization library.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.fadeVariants - The animation variants used for fading transitions.
 * @param {function} props.setPeople - Function to update the number of guests selected.
 * @param {function} props.setStep - Function to change the current step in the booking process.
 * @param {number} props.people - The current number of guests selected.
 * @param {Object} props.formData - An object holding form data for the reservation.
 * @param {function} props.setFormData - Function to update the form data.
 *
 * @returns {JSX.Element} The rendered component with buttons for guest selection.
 *
 * @example
 * <GuestStep
 *   fadeVariants={fadeVariants}
 *   setPeople={setPeople}
 *   setStep={setStep}
 *   people={people}
 *   formData={formData}
 *   setFormData={setFormData}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const GuestStep = ({ fadeVariants, setPeople, setStep, people, formData, setFormData }) => {
  const [t] = useTranslation("global");

  return (
    <motion.div variants={fadeVariants} initial="hidden" animate="visible" exit="exit" >
      <h2 className="text-xl mb-4">{`${t(`GuestStep.howmanyguests`)}`}</h2>
      <div className="grid grid-cols-4 gap-4">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((num) => (
          <button type='button' key={num} onClick={(e) => (setPeople(num), setStep(2),setFormData({ ...formData, Pax:num }))} className={`p-2 border rounded transition-transform duration-300 ease-in-out transform hover:scale-105 hover:bg-teal-100 hover:border-teal-600 ${ people === num ? 'bg-teal-100 border-teal-600' : 'border-gray-300' }`} >
            {num}
          </button>
        ))}
      </div>
    </motion.div>
  );
};
