import dayjs from "dayjs";
import { BedDouble, Calendar, ChevronRight, LogIn, Package, Users, } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

export const ReservationCard = ({ reservations }) => {
  const [t] = useTranslation("global");
  const { platformUid } = useParams();

  return (
    <>
      {reservations.reservations.map((reservation, index) => (
        <Link to={`/hotel/${reservations.hotel.hotelId.substring(0,8)}/${reservation.detailid}`} key={index} className=" flex flex-col bg-white border-violet-100 border-2 rounded-lg shadow-md p-4 mb-4 hover:shadow-lg transition-shadow duration-300" >
          <div className="flex justify-between items-center mb-2">
            <span className="text-md font-semibold text-gray-600"> {`${t("reservation")}`} {reservation.resno} </span>
            <div className="flex justify-center items-center space-x-3">
              <div className="text-gray-600 hover:text-gxp transition-colors duration-200" aria-label="Online Check-in" >
                <ChevronRight className="w-5 h-5" />
              </div>
            </div>
          </div>
          <div className="flex items-center mb-2 text-gray-600">
            <Calendar className="w-5 h-5 mr-2 text-gxp" />
            <span className="text-sm">
              {dayjs(reservation.checkin).format(t("dayjs_format"))} - {" "}
              {dayjs(reservation.checkout).format(t("dayjs_format"))}
            </span>
          </div>
          <div className="flex items-center text-gray-600 mb-2">
            <BedDouble className="w-5 h-5 mr-2 text-gxp" />
            <p className="text-sm">
            {`${t("Checkout.room")}`} {reservation.room} - {reservation.categoryDescription}
            </p>
          </div>
          <div className="flex items-center text-gray-600 mb-2">
            <Users className="w-5 h-5 mr-2 text-gxp" />
            <p className="text-sm">{reservation.pax + reservation.children + reservation.children2 + reservation.children3} {`${t("HotelReservationDetails.guests")}`} ({reservation.pax} {`${t("HotelReservationDetails.adults")}`},{" "} {reservation.children + reservation.children2 + reservation.children3}{" "} {`${t("HotelReservationDetails.children")}`}){" "} </p>
          </div>
          <div className="flex items-center text-gray-600">
            <Package className="w-5 h-5 mr-2 text-gxp" />
            <p className="text-sm">{reservation.packDescription}</p>
          </div>
        </Link>
      ))}
    </>
  );
};
