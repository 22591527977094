import { useEffect, useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, ChevronsDownUp, ChevronsUpDown, ChevronUp } from "lucide-react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getHostCheckoutInfo, getReservationByDetailId } from "../../services";
import { toast } from "react-toastify";
import React from "react";
import { AppContext } from "../../context/AppContext";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import onlineCheckInDesktop from "../../assets/images/onlineCheckInDesktop.jpeg";
import onlineCheckIn from "../../assets/images/onlineCheckIn.jpeg";
import { Loading } from "../Loading";
import { useWindowSize } from "../../hooks/useWindowSize";
import { HeadersPage } from "../../components";

export const Checkout = () => {
  const [data, setData] = useState({
    checkoutData: null,
    reservation: null,
    groupedLines: {},
  });
  const [expandedDates, setExpandedDates] = useState([]);
  const { detailId } = useParams();
  const [cookie] = useCookies(["sUid", "currentHotel", "guest"]);
  const [state] = useContext(AppContext);
  const [t] = useTranslation("global");
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [allExpanded, setAllExpanded] = useState(false);

  const processCheckoutLines = (lines) => {
    // Remove os pares de itens anulados
    const nonVoidedLines = lines.filter((line) => {
      if (line.BookingId === 0 && line.VoidedBy === 0) return true;
      if (line.VoidedBy !== 0) {
        const voidingItem = lines.find(
          (voidLine) => voidLine.BookingId === line.VoidedBy
        );
        return !voidingItem;
      }
      if (line.VoidId !== 0) {
        const voidedItem = lines.find(
          (voidLine) => voidLine.BookingId === line.VoidId
        );
        return !voidedItem;
      }
      return true;
    });

    // Processa as linhas e agrupa por data
    const processedLines = nonVoidedLines.map((line) => ({ ...line, Description: line.RecordType === 2 ? `(Previsão) ${line.Description}` : line.Description, }));

    // Agrupa por data mantendo a data original para ordenação
    const groupedByDate = processedLines.reduce((acc, line) => {
      const formattedDate = formatDate(line.Date);
      if (!acc[formattedDate]) {
        acc[formattedDate] = {
          originalDate: line.Date,
          lines: []
        };
      }
      acc[formattedDate].lines.push(line);
      return acc;
    }, {});

    // Ordena as datas usando a data original
    const orderedDates = Object.entries(groupedByDate)
      .sort(([, a], [, b]) => new Date(a.originalDate) - new Date(b.originalDate))
      .reduce((acc, [formattedDate, { lines }]) => {
        acc[formattedDate] = lines;
        return acc;
      }, {});

    return orderedDates;
  };

  const fetchData = async () => {
    if (!detailId || !cookie.sUid || !cookie.currentHotel) return;
    setIsLoading(true);
    try {
      // Fazer ambas as chamadas em paralelo
      const [checkoutInfo, reservationInfo] = await Promise.all([
        getHostCheckoutInfo(cookie.sUid, detailId, cookie.currentHotel),
        getReservationByDetailId(detailId, cookie.currentHotel, cookie.sUid),
      ]);

      const groupedLines = checkoutInfo.data.Lines ? processCheckoutLines(checkoutInfo.data.Lines) : {};

      setData({ checkoutData: checkoutInfo.data, reservation: reservationInfo[0], groupedLines, });
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Unable to get checkout information!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [detailId, cookie.sUid, cookie.currentHotel]); // Incluir todas as dependências necessárias

  const toggleDate = (date) => { setExpandedDates((prev) => prev.includes(date) ? prev.filter((d) => d !== date) : [...prev, date] ); };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("pt-PT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const toggleAllDates = () => { setAllExpanded(!allExpanded); setExpandedDates(allExpanded ? [] : Object.keys(data.groupedLines)); };

  const formatDate = (dateString) => { return dayjs(dateString).format(t("dayjs_format_long")); };

  const { reservation, checkoutData, groupedLines } = data;

  console.log(reservation)

  return (
    <>
      { (
        <div className={`${state.darkMode ? "" : "page_bg_color"} antialiased dark:bg-gray-900`}>
          <section className="shadow-md overflow-hidden flex-1">
            <HeadersPage title={`${t(`Checkout.checkout`)}`} room={reservation?.room}/>

            <div className="p-6">
              {isLoading ? ( <Loading /> ) : (
                <>
                  {reservation && checkoutData ? (
                    <>
                      <div className="mb-4 flex justify-between items-center">
                        <button onClick={toggleAllDates} className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors" >
                          {allExpanded ? ( <ChevronsDownUp className="w-4 h-4" /> ) : ( <ChevronsUpDown className="w-4 h-4" /> )}
                          {allExpanded ? t("Checkout.collapseAll") : t("Checkout.expandAll")}
                        </button>
                        <span className="text-lg font-semibold"> {t("Checkout.balance")}{" "} {formatCurrency(checkoutData.Balance)} </span>
                      </div>
                      <table className="w-full">
                        <thead>
                          <tr className="bg-gray-200 text-left">
                            <th className="py-2 px-4 w-full">{t("Checkout.description")}</th>
                            <th className="py-2 px-4">{t("Checkout.quantity")}</th>
                            <th className="py-2 px-4">{t("Checkout.total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(groupedLines).map(([date, lines]) => (
                            <React.Fragment key={date}>
                              <tr className="bg-gray-100 cursor-pointer hover:bg-gray-200 transition-colors duration-150" onClick={() => toggleDate(date)} >
                                <td colSpan="4" className="py-2 px-4">
                                  <div className="flex justify-between items-center font-semibold">
                                    <span>{date}</span>
                                    {expandedDates.includes(date) ? ( <ChevronUp className="w-5 h-5" /> ) : ( <ChevronDown className="w-5 h-5" /> )}
                                  </div>
                                </td>
                              </tr>
                              <AnimatePresence initial={false}>
                                {expandedDates.includes(date) && (
                                  <motion.tr initial="collapsed" animate="expanded" exit="collapsed" variants={{ expanded: { height: "auto", opacity: 1 }, collapsed: { height: 0, opacity: 0 }, }} transition={{ duration: 0.3, ease: "easeInOut" }} >
                                    <td colSpan="4" className="p-0">
                                      <motion.div initial="collapsed" animate="expanded" exit="collapsed" variants={{ expanded: { height: "auto", opacity: 1 }, collapsed: { height: 0, opacity: 0 }, }} transition={{ duration: 0.2, ease: "easeInOut" }} style={{ overflow: "hidden" }} >
                                        <table className="w-full">
                                          <tbody>
                                            {lines.map((line, index) => (
                                              <tr key={index} className={ index % 2 === 0 ? "bg-white" : "bg-gray-50" } >
                                                <td className="py-2 px-4 w-full"> {line.Description} </td>
                                                <td className="py-2 px-4">{line.Pieces}</td>
                                                <td className="py-2 px-4 text-right">
                                                  {formatCurrency(line.Price)}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </motion.div>
                                    </td>
                                  </motion.tr>
                                )}
                              </AnimatePresence>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : ( <p>{t("Checkout.checkin")}</p> )}
                </>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
};