import { Link } from "react-router-dom"


export const PageNotFound = () => {

  return (
    <div className="antialiased bg-gray-100 dark:bg-gray-900">
    <main className='h-auto pt-[61px] md:ml-[255px]'>
    <section className="bg-white dark:bg-gray-900 md:h-dvh md:fixed md:left-20 w-screen h-screen">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center px-10">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-gray-700 dark:text-blue-600">404</h1>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-600 md:text-4xl dark:text-white">Something's missing.</p>
                <p className="mb-4 text-lg font-light text-gray-600 dark:text-gray-400">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>
                <Link to="/" className="inline-flex text-gray-600 dark:text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back to Overview</Link>
            </div>
        </div>
    </section>
    </main>
    </div>
)
}
