import { useParams } from 'react-router-dom';
import { Navbar, SidebarHotel, Footer } from '../components';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { toast } from 'react-toastify';
import { getHotelById, getWebText } from '../services';
import { useCookies } from 'react-cookie';

export const WebText = () => {

    const [state, dispatch] = useContext(AppContext);
    const {platformUid, key} = useParams();
    const [principalHotel, setPrincipalHotel] = useState();
    const [webText, setWebText] = useState();
    const [cookie, setCookie] = useCookies(['platformUid']); // eslint-disable-line

    useEffect(() => {
        getHotelById(platformUid).then((data) => {
        setPrincipalHotel(data)
        dispatch({ type: "SET_CURRENT_HOTEL", payload: data });
        setCookie('platformUid', platformUid, {path:"/"})
        }).catch(error => {
          console.error("Error fetching hotel info:", error);
          toast.error("Unable to get hotel info!");
        });
      }, []); // eslint-disable-line

      useEffect(() => {
        if (principalHotel) {
          // Despacha a ação apenas quando principalHotel for atualizado
          dispatch({ type: "SET_CURRENT_HOTEL", payload: principalHotel });
          setCookie('platformUid', platformUid, {path:"/"})
        }
      }, [principalHotel]); // eslint-disable-line

      useEffect(() => {
        if(state.currentHotel.subscriptionKey){
        getWebText(state.currentHotel.defaultCultureCode,key,state.currentHotel.subscriptionKey).then((data) => {
        setWebText(data[0]?.TextPlain)
        }).catch(error => {
          console.error("Error fetching hotel info:", error);
          toast.error("Unable to get hotel info!");
        });}
      }, [key, principalHotel]); // eslint-disable-line

    return (
          <div className={`${ state.darkMode ? "" : "" } antialiased dark:bg-gray-900 h-full`} >
                <section className="flex-grow p-4">
                    <div className={`text-justify`} dangerouslySetInnerHTML={{ __html: webText }}>{}</div>
                </section>
          </div>
      );
}
