export default class Api {
    static baseUrl = `${process.env.REACT_APP_BACKEND_IP}`; // Replace with your actual base URL

    static buildUrl(url, data) {
      const params = Api.buildParameterString(data);
      if (params !== "") {
        url += url.includes("?") ? `&${params}` : `?${params}`;
      }
      return url;
    }

    static buildParameterString(data) {
      if (data == null) return "";

      if (typeof data === "string") return data;

      if (typeof data === "object") {
        return Object.entries(data)
          .flatMap(([key, value]) => {
            if (Array.isArray(value)) {
              return value.length > 0
                ? value.map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
                : [];
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
          })
          .filter(Boolean)
          .join("&");
      }

      return "";
    }

    static getUrl(endpoint, params = {}) {
      const fullUrl = new URL(endpoint, Api.baseUrl);
      return Api.buildUrl(fullUrl.toString(), params);
    }
  }