import { AlertCircle, AlertTriangle, CheckCircle2, ChevronLeft } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect } from "react";


export const SubMenu = ({ menu, onBack, posSection, setActiveSection, setIsExiting }) => {
   const getCompletionIndicator = (rate) => {
     if (rate <= 40) {
       return ( <AlertCircle className="w-5 h-5 text-red-500" aria-label="Low completion rate" /> );
     } else if (rate <= 70) {
       return (
         <AlertTriangle className="w-5 h-5 text-yellow-500" aria-label="Medium completion rate" /> );
     } else {
       return (
         <CheckCircle2 className="w-5 h-5 text-green-500" aria-label="High completion rate" />
       );
     }
   };

   useEffect(()=>{
       setActiveSection(posSection)
   },[])

     // Lista de types que devem aparecer como rasurados
     const typesToStrikethrough = [
      "EMENU",
    ];

   return (
     <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }} transition={{ duration: 0.3 }} className="w-full" >
       {/* Header */}
       <div className="flex items-center gap-4 mb-6">
         <motion.button onClick={() => { onBack(); setIsExiting(true); setActiveSection(null) }} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="p-2 rounded-lg bg-white shadow" >
           <ChevronLeft />
         </motion.button>
         <h2 className="text-xl font-semibold">{menu.Description}</h2>
       </div>

       {/* Grid de subitems */}
       <div className="grid grid-cols-2 gap-2">
         {menu.subItems.map((subItem, index) => (
           <motion.div key={subItem.Id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, delay: index * 0.1 }} exit={{ opacity: 0, y: 20 }}>
             <Link to={subItem.LinkTo} className="flex flex-col bg-white min-h-24 rounded-lg shadow p-4 w-full h-full relative transition duration-300 ease-in-out transform hover:shadow-lg" >
               <div className="flex justify-between items-center h-full">
                 <div className="flex flex-col items-center flex-grow justify-center text-center">
                   <span dangerouslySetInnerHTML={{ __html: subItem.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="text-gxp mb-2" />
                   <span className={`${typesToStrikethrough.includes(subItem.Type) ? "line-through" : ""}`}>{subItem.Description}</span>
                 </div>
                 {subItem.rate && (
                   <div className="flex items-center gap-2 absolute right-7 top-5">
                     {getCompletionIndicator(subItem.rate)}
                     <span className={`text-sm text-gray-600 ${ subItem.rate <= 40 ? "text-red-500" : subItem.rate <= 70 ? "text-yellow-500" : "text-green-500" }`} >
                       {subItem.rate}%
                     </span>
                   </div>
                 )}
               </div>
             </Link>
           </motion.div>
         ))}
       </div>
     </motion.div>
   );
 };