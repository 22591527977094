import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { AlertTriangle } from "lucide-react";

/**
 * @module Phone
 * @description Component for handling a guest's phone number input, including country dial code selection.
 * This component provides a dropdown for selecting a dial code, a text input for the phone number,
 * and validates whether the phone number input is required based on provided rules.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.initialData - The initial data containing country information and dial codes.
 * @param {Object} props.guest - The guest object containing data such as the phone number.
 * @param {function} props.handleGuestChange - Function to update guest data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered phone number input field with dial code selection.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Phone component
 * <Phone
 *   initialData={initialData}
 *   guest={guestData}
 *   handleGuestChange={handleGuestChange}
 *   requiredFields={requiredFields}
 * />
 */
export const Phone = ({ initialData, guest, handleGuestChange, requiredFields,error, cS }) => {
  const [selectedDialCode, setSelectedDialCode] = useState(""); // State to store the selected dial code
  const [phoneNumber, setPhoneNumber] = useState(guest.data.Mobile || ""); // State to store the phone number
  const [isDialCodeSelected, setIsDialCodeSelected] = useState(false); // State to check if a dial code has been selected
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("global");

  // Check if the Phone field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "Phone" && field.IsMandatory
  );

  const countries = useMemo(() => {
    return initialData.CountryList.map(country => ({
      ...country,
      dialCode: initialData.DialCodes[country.IsoCode] || ''
    }));
  }, [initialData.CountryList, initialData.DialCodes]);


  /**
   * @function handleDialCodeChange
   * @description Handles changes to the dial code selection.
   * This function is triggered when the user selects a new dial code from the dropdown.
   * It updates the local state with the new dial code and formats the phone number
   * to include the selected dial code, ensuring the previous dial code is removed
   * from the phone number if it exists.
   *
   * @param {Event} e - The event object from the change event of the select element.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // When a user selects a new dial code from the dropdown:
   * handleDialCodeChange(event);
   *
   * // This will result in updating the guest's phone number to include the new dial code,
   * // formatted as "dialCode phoneNumber", without any previous dial code.
   *
   * @see handleGuestChange
   */
  const handleDialCodeChange = (selected) => {
    const dialCode = selected.value; // Aqui extraímos o dialCode selecionado
    setSelectedDialCode(dialCode); // Atualiza o estado com o código de discagem
    setIsDialCodeSelected(true);

    // Atualiza o número de telefone com o código de discagem
    if (phoneNumber) {
      handleGuestChange(
        guest.id,
        "Phone",
        `${dialCode} ${phoneNumber.replace(/^\+\d+\s/, "")}`
      );
    }
  };

  /**
   * @function handlePhoneChange
   * @description Updates the phone number based on user input.
   * This function is triggered when the user types in the phone number input field.
   * It captures the current input value, updates the local state with the new phone number,
   * and formats the phone number by prepending the selected dial code before saving it
   * to the guest's data.
   *
   * @param {Event} e - The event object from the change event of the input element.
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // When a user types a new phone number:
   * handlePhoneChange(event);
   *
   * // This will result in updating the guest's phone number to be formatted as
   * // "selectedDialCode phoneNumber", where selectedDialCode is the currently selected
   * // dial code from the dropdown.
   *
   * @see handleGuestChange
   */
  const handlePhoneChange = (e) => {
    const value = e.target.value; // Get the value from the phone input
    // Get the value from the phone input
    setPhoneNumber(value);
    // Call the function to update the guest with the formatted phone number
    handleGuestChange(guest.id, "Mobile", `${selectedDialCode} ${value}`); // Pass formatted value to the function
  };

  return (
    <div>
      <label htmlFor="telefone" className={`pl-2 pr-2 w-fit rounded-xl inline-block translate-y-3.5 translate-x-[105px] text-center justify-center bg-white text-sm font-medium text-gray-700 mb-1 ${parseInt(cS) ? "OC-bg" : ``} `} > {`${t(`Phone.phonenumber`)}`} <span className="text-gray-400 ml-2">{isRequired?`( * )\u00A0`: ``}</span> </label>
      <div className="flex h-full w-full">
        <div className="flex h-full w-full items-stretch">
          <Select suffixIcon={ <ChevronDown className={`text-gray-700 transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            className={`w-full py-2 text-xl rounded-l-lg border text-gray-700 ${error ? 'border-red-500' : 'border-gray-700'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
            style={{ width: "100px", height: "45.5px" }}
            onChange={handleDialCodeChange}
            placeholder={`${t(`Phone.select`)}`}
            optionLabelProp="label"
            showSearch
            dropdownStyle={{ minWidth: "260px" }}
            labelInValue
            value={ selectedDialCode ? { value: selectedDialCode, label: selectedDialCode } : undefined }
            filterOption={(input, option) => {
              const translatedDescription = initialData.CountryList.find((country) => country.dialCode === option.value)?.TranslatedDescription || "";
              return translatedDescription .toLowerCase() .includes(input.toLowerCase());
            }}
            virtual={false}

          >
            {countries.map((country) => (
              <Select.Option key={country.IsoCode} value={country.dialCode} label={ <div className="flex items-center"> <img src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`} alt={`${country.TranslatedDescription} flag`} className="w-6 h-4 mr-2" /> <span className="text-l">{country.dialCode}</span> </div> } >
                <div className="flex items-center">
                  <img src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`} alt={`${country.TranslatedDescription} flag`} className="w-6 h-4 mr-2" />
                  <span className="text-l">
                    {country.TranslatedDescription}
                  </span>
                  <span className="ml-2 text-l text-gray-500">
                    {country.dialCode}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>

        <div className="justify-center w-full h-full">
          <input name="Mobile" onChange={handlePhoneChange} value={phoneNumber} type="tel" id="telefone" className={`w-full px-4 py-2 rounded-r-lg border ${error ? 'border-red-500' : 'border-gray-700'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-xl transition duration-150 ease-in-out`} />
        </div>
        </div>
      </div>
      {error && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span> </div>}
    </div>
  );
};
