import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getReservationByDetailId } from "../../services";
import { toast } from "react-toastify";
import { MainSection } from "./components/MainSection";
import { AppContext } from "../../context/AppContext";
import { AnimatePresence, motion } from "framer-motion";
import { SliderSection } from "./components/SliderSection";

export const HotelHome = () => {
  const { platformUid, detailId } = useParams();
  const [reservation, setReservation] = useState();
  const [cookie, setCookie] = useCookies(["platformUid", "currentHotel", "GUid", "reservation", "detailId"]); // eslint-disable-line
  const [state, dispatch] = useContext(AppContext);
  const [isSubmenuActive, setIsSubmenuActive] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false); // Novo estado para controlar a animação


  useEffect(() => {
    setCookie("detailId", detailId, { path: "/" });
  }, [detailId]); // eslint-disable-line

  const handleExitAnimation = () => {
    setIsAnimating(true);
    setActiveSection(null); // Garante que ambas as seções estão saindo juntas
    setTimeout(() => {
      setIsAnimating(false); // Permite novas animações após a saída
    }, 300); // Ajuste a duração conforme necessário
  };

  const fetchReservationByDetailId = async () => {
    if (cookie.currentHotel?.subscriptionKey && detailId) {
      try {
        const results = await getReservationByDetailId(detailId, cookie.currentHotel, cookie.sUid);
        setReservation(results[0]);
        dispatch({ type: "SET_USER_RESERVATION", payload: results[0] });
      } catch (error) {
        console.log(error);
        toast.error("Unable to get reservations!");
      }
    }
  };

  useEffect(() => {
    if (cookie.currentHotel?.subscriptionKey && detailId) {
      fetchReservationByDetailId();
    }
  }, [detailId]); // eslint-disable-line

  return (
    <AnimatePresence mode="wait">
      {cookie.currentHotel && detailId && state.reservation && (
        <motion.div key="main-content" className="w-full" initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 100 }} transition={{ duration: 0.3 }} >
          <main className="bg-gxp_bg max-w-4xl w-full h-full items-center mx-auto ">
            <motion.div key="sections-container" className="w-full items-center" initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 100 }} transition={{ duration: 0.3 }} >
              {!isAnimating && (activeSection === "MAIN" || activeSection === null) && (
                <MainSection detailId={detailId} onMenuStateChange={setIsSubmenuActive} posSection="MAIN" setActiveSection={setActiveSection} setIsExiting={handleExitAnimation} />
              )}
               {!isAnimating && (activeSection === "SLIDER" || activeSection === null) && (
                <SliderSection  posSection="SLIDER" />
              )}
              {!isAnimating && (activeSection === "MAIN-2" || activeSection === null) && (
                <MainSection detailId={detailId} onMenuStateChange={setIsSubmenuActive} posSection="MAIN-2" setActiveSection={setActiveSection} setIsExiting={handleExitAnimation} />
              )}
            </motion.div>
          </main>
        </motion.div>
      )}
    </AnimatePresence>
  );
};