import { useEffect, useState } from 'react'

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        isMobile: false
      });

      useEffect(() => {
        function handleResize() {
          setWindowSize({
            width: window.innerWidth,
            isMobile: window.innerWidth <= 640 // 640px é o breakpoint sm do Tailwind
          });
        }

        window.addEventListener("resize", handleResize);
        handleResize(); // Chama na montagem inicial

        return () => window.removeEventListener("resize", handleResize);
      }, []);

      return windowSize;
}
