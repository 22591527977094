

/**
 * Function that send a request to API to get host profile in a hotel.
 * @param {string} sUid Host short GUid
 * @param {string} profileKey Host profileKey
 * @param {string} subscriptionKey Hotel individual key to send requests to Azure
 * @returns host known info
 */
export async function getHostProfile(sUid, profileKey, subscriptionKey){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": subscriptionKey}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/Profile?sUid=${sUid}&profileKey=${profileKey}`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

/**
 * Function that send a request to API to get host reservation in a hotel
 * @param {string} sUid Host short GUid
 * @param {string} profileKey host profileKey
 * @param {object} hotel Hotel that has a subscriptionKey to send requests to Azure
 * @returns host reservation info.
 */
export async function getHostReservation(sUid, profileKey, hotel){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,}
    }

    // const url = Api.getUrl('/gxplocal/bridge/Gxp/Reservation/Search?', {"data.profileShortUid": sUid});

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/Reservation/Search?data.profileShortUid=${sUid}&data.profileKey=${profileKey}`, requestOptions)
    // const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/Reservation/Search?data.profileShortUid=${sUid}`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return {hotel: hotel,
            reservations: data}
}



/**
 * Function that send a request to API to get host reservation in a hotel
 * @param {string} sUid Host short GUid
 * @param {string} profileKey host profileKey
 * @param {object} hotel Hotel that has a subscriptionKey to send requests to Azure
 * @returns host reservation info.
 */
export async function getHostCheckoutInfo(sUid, detailId, hotel){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,}
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/CheckoutInfo?sUid=${sUid}&detailId=${detailId}`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return {data}
}



