import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { HostFormComplete } from "./components/HostFormComplete";
import { toast } from "react-toastify";
import { getHostProfile, getHotelPmsIdPrefix, searchReservation } from "../../services";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

export const OnlineCheckIn = () => {
  const { platformUid, detailId, resNo, sUid, cS } = useParams();
  const [cookies, setCookie] = useCookies([ "cultureCode", "GUid", "currentHotel", "sUid", "profileKey", "guest", "isProfileLoaded", ]);
  const [data, setData] = useState({ reservation: null, groupHotels: [], isLoading: true, isInitialized: false, profileLoaded: false, reservationLoaded: false });
  const [t] = useTranslation("global");
  const { pathname } = useLocation();
  const showMenus = !pathname.includes("/checkindirect");

  const loadHotels = async () => {
    if (!platformUid || !pathname.includes("/checkindirect")) return;

    try {
      const hotelsData = await getHotelPmsIdPrefix(platformUid);
      setData(prev => ({ ...prev, groupHotels: hotelsData }));

      // Encontra e define o hotel principal
      for (const hotel of hotelsData) {
        const pmsIdPrefix = hotel.pmsId.slice(0, 8);
        if (pmsIdPrefix === platformUid) {
          setCookie("currentHotel", hotel, { path: "/" });
          break;
        }
      }

      // Define sUid nos cookies se existir
      if (sUid) {
        setCookie("sUid", sUid, { path: "/" });
      }
    } catch (error) {
      console.error("Error loading hotels:", error);
      toast.error("Unable to get hotels!");
    }
  };

  const loadProfile = async () => {
    if (!cookies.currentHotel?.subscriptionKey || !cookies.sUid || data.profileLoaded) return;

    try {
      const profileResult = await getHostProfile(
        cookies.sUid,
        cookies.profileKey,
        cookies.currentHotel.subscriptionKey
      );

      setCookie("profileKey", profileResult.ProfileKey, { path: "/" });
      setCookie("GUid", profileResult.Uid, { path: "/" });
      setCookie("guest", JSON.stringify(profileResult), { path: "/" });
      setCookie("isProfileLoaded", true, { path: "/" });

      setData(prev => ({ ...prev, profileLoaded: true }));
    } catch (error) {
      console.error("Error loading profile:", error);
      toast.error(t("OnlineCheckIn.errorhost"));
    }
  };

  const loadReservation = async () => {
    if (!cookies.currentHotel || !cookies.GUid || !detailId || !resNo || data.reservationLoaded) return;

    try {
      const reservationResponse = await searchReservation( resNo, detailId, cookies.currentHotel, cookies.GUid, cookies.currentHotel.defaultCultureCode );
      setData(prev => ({
        ...prev,
        reservation: reservationResponse.Data?.Details[0],
        reservationLoaded: true,
        isLoading: false
      }));
    } catch (error) {
      console.error("Error loading reservation:", error);
      toast.error(t("OnlineCheckIn.errorreservation"));
      setData(prev => ({ ...prev, isLoading: false }));
    }
  };

  console.log(cookies.currentHotel)

  // Efeito para carregar hotéis - só executa uma vez no início
  useEffect(() => {
    if(!cookies.currentHotel.hotelId.substring(0,8)==platformUid){
      loadHotels();
    }
  }, [platformUid]); // eslint-disable-line

  // Efeito para carregar perfil - depende dos cookies necessários
  useEffect(() => {
    if (cookies.currentHotel?.subscriptionKey && cookies.sUid && !data.profileLoaded) {
      loadProfile();
    }
  }, [cookies.currentHotel?.subscriptionKey, cookies.sUid]); // eslint-disable-line

  // Efeito para carregar reserva - só executa quando o perfil estiver carregado
  useEffect(() => {
    if (data.profileLoaded && !data.reservationLoaded) {
      loadReservation();
    }
  }, [data.profileLoaded]); // eslint-disable-line

  return (
    <AnimatePresence mode="wait">
      {cS && cookies.currentHotel && cookies.cultureCode && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{
            type: "tween",
            ease: "easeOut",
            duration: 0.3
          }}
          className={`antialiased dark:bg-gray-900 ${parseInt(cS) ? "OC-bg" : ``}`}
        >
          <section className={`flex-grow`}>
            <HostFormComplete
              cS={cS}
              showMenus={showMenus}
              hotel={cookies.currentHotel}
              reservation={data.reservation}
              setReservation={(reservation) => setData(prev => ({ ...prev, reservation }))}
              cultureCode={cookies.cultureCode}
            />
          </section>
        </motion.div>
      )}
    </AnimatePresence>
  );
};